import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { DataGrid, frFR } from '@mui/x-data-grid';

import { Container, Button, Typography, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Box, TextField, IconButton } from '@mui/material';
import { AuthContext } from '../../navigation/AuthProvider';
import { Role } from '../../common/enum';
import { Grid } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { Delete, Edit } from '@mui/icons-material';

/**
 * Interface qui affiche une liste d'activités dans un DataGrid
 * @param {object} match Permet de récupérer l'url exacte où se trouve l'utilisateur en ce moment
 * @returns Un Container dans lequel se trouve un DataGrid. Chaque colonne est un attribut d'une activité et chaque ligne une activité
 */
function List({ match }) {
    const { path } = match;
    const {getAllActivities, getActivitiesFromOneOrganizer, getCurrentUser, deleteActivity, getAllRealActivities, hasRole} = useContext(AuthContext);
    const history = useHistory();

    // Contiendra toutes les activités organisées par l'association qui sont récupérées de la BD
    const [activities, setActivities] = useState(null);
    // Utilisé pour ouvrir ou fermer la fenêtre de confirmation de suppression d'une activité
    const [openDialog, setOpenDialog] = useState(false);
    // Activité qui veut être supprimée. Utilisé pour la confirmation de suppression
    const [deletedActivity, setDeletedActivity] = useState(null);

    // Texte rentré dans la barre de recherche
    const [searchText, setSearchText] = useState('');
    const [rows, setRows] = useState();

    const user = getCurrentUser();
    // Id de l'association actuellement connectée sur la plateforme
    const id = user.profile.idProfile;

    /**
     * Récupération de toutes les activités organisées par une association
     * @param {number} id id de l'association
     */
    const getActivities = (id) => {
      if (!hasRole(Role.ADMIN)) {
        getActivitiesFromOneOrganizer(id)
        .then(response => {
          setActivities(response);
          setRows(response);
        });
      } else {
        if (hasRole(Role.SUPERADMIN)) {
          getAllActivities()
          .then(response => {
            setActivities(response.data);
            setRows(response.data);
          });
        } else {
          getAllRealActivities()
          .then(response => {
            setActivities(response.data);
            setRows(response.data);
          })
        }
      }
    }

    useEffect(() => {
      getActivities(id);
    }, []);

    // React ouvre le "dialog" de confirmation une fois qu'il sait quelle activité doit être supprimée
    useEffect(() => {
      handleClickOpenDialog();
    }, [deletedActivity])

    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

    const handleClickOpenDialog = () => {
      setOpenDialog(true);
    };

    const getNameAsso = (params) => {
      return params.row.organizer.assoProfile === null ? (""):(params.row.organizer.assoProfile.name);
    }

    // Gestion des colonnes du tableau
    const beginningColumns = [
      {field: 'dateBegin', minWidth: 130, flex: 1, headerName: "Date"}
    ];
    const endingColumns = [
      {field: 'name', minWidth: 150, flex: 1, headerName: "Nom de l'activité"},
      {field: 'address', minWidth: 200, flex: 2.3, headerName: "Adresse de l'activité"},
      {field: ' ', type: 'actions', flex: 1, minWidth: 200, renderCell: (params) => (
        <Grid container direction="row" justifyContent="space-evenly">
          <Button
            variant="outlined"
            startIcon={<Edit />}
            onClick={() => {
              history.push(`${path}/edit/${params.id}`);
            }}
          >Editer</Button>
          <Button
            variant="outlined"
            startIcon={<Delete />}
            color='error'
            onClick={() => {
              setDeletedActivity(params.row);
            }}
          >Supprimer</Button>
        </Grid>
      )}
    ]

    const columns =
    [
      ...beginningColumns,
      ...endingColumns
    ];

    /**
     * Gestion barre de recherche d'activités
     * @param {*} props Props que la barre de recherche récupère
     * @returns Une Box contenant un TextFiel à remplir pour effectuer une recherche
     */
    function QuickSearchToolbar(props) {
      return (
        <Box
          sx={{
            p: 0.5,
            pb: 0,
          }}
        >
          <TextField
            variant="standard"
            value={props.value}
            onChange={props.onChange}
            placeholder="Rechercher un nom d'activité..."
            autoFocus
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: props.value ? 'visible' : 'hidden' }}
                  onClick={props.clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
            sx={{
              width: {
                xs: 1,
                sm: 'auto',
              },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              '& .MuiSvgIcon-root': {
                mr: 0.5,
              },
              '& .MuiInput-underline:before': {
                borderBottom: 1,
                borderColor: 'divider',
              },
            }}
          />

          <Button
            variant="contained"
            onClick={() => requestSearch(searchText)}
          >Chercher</Button>
        </Box>
      )
    }

    /**
     * Préparation du regex pour la recherche
     * @param {*} value Valeur modifiée pour arriver au regex
     * @returns Le nouveau regex
     */
    function escapeRegExp(value) {
      return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    }

    /**
     * Recherche effectuée en fonction de la valeur entrée dans le champ
     * @param {*} searchValue Valeur entrée par l'utilisateur
     */
    const requestSearch = (searchValue) => {
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = activities.filter((row) => {
        return searchRegex.test(row["name"].toString());
      })
      setRows(filteredRows);
    }

    return (
      <Container maxWidth="xl" style={{flexGrow: 1, display: "flex", width: '100%', flexDirection: "column"}}>
        <Typography variant="h4">{hasRole(Role.ADMIN) ? ("Toutes les activités") : ("Mes activités")}</Typography>
        <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">Ajouter une activité</Link>
        {rows && (
          <DataGrid
            components={{ Toolbar: QuickSearchToolbar }}
            autoHeight
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            getRowId={(r) => r.idActivity}
            columns={
              hasRole(Role.ADMIN) ? (
                [
                  ...beginningColumns,
                  {field: 'nameAsso', minWidth: 150, flex: 1.5, headerName: "Nom de l'association", valueGetter: getNameAsso},
                  ...endingColumns
                ]
              ) : (
                columns
              )
            }
            rows={rows}
            loading={rows === null}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => setSearchText(event.target.value),
                clearSearch: () => {
                  requestSearch('');
                  setSearchText('')
                },
              }
            }}
          />
        )}

        {deletedActivity && (
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirmation de suppression"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Voulez-vous vraiment supprimer l'activité "{deletedActivity.name}" ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => handleCloseDialog()}
              >Non</Button>
              <Button
                variant="contained"
                onClick={() => {
                  deleteActivity(deletedActivity.idActivity, id)
                  .then(result => {
                    handleCloseDialog();
                    getActivities(id);
                  })
                }}
              >Oui</Button>
            </DialogActions>
          </Dialog>
          )}

      </Container>
    );
}

export { List };