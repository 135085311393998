import React, {useState, useContext, useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Autocomplete, CircularProgress, FormControlLabel, ListItemIcon} from '@mui/material';
import {List, ListItem, ListItemButton, ListItemText, Checkbox} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AuthContext } from '../../navigation/AuthProvider';
import {styles} from '../../common/style';
import mapboxSdk from '@mapbox/mapbox-sdk/services/geocoding';
import { constants } from '../../common/constants';
import { useHistory } from "react-router";
import { PATHS } from '../../common/enum';

const theme = createTheme();

/**
 * Fonction qui gère la création d'un compte pour un utilisateur
 * @returns Un formulaire de création de compte d'association avec le choix du nom, de l'adresse, du numéro de téléphone et de l'adresse mail de contact, du site web, de la description de l'association, de l'adresse mail et du mot de passe utilisés pour pouvoir se connecter
 */
export default function SignUpScreen() {
  const {signUp, errorAuth, getAllRealAssoCreatedByAdmin, registerAssoCreatedByAdmin, isEmailUsed} = useContext(AuthContext);

  const [assoChoices, setAssoChoices] = useState();
  const [checkedAsso, setCheckedAsso] = useState(null);
  const [nameAsso, setNameAsso] = useState("");
  // True si l'utilisateur a cliqué sur "créer mon compte", false sinon
  const [buttonClicked, setButtonClicked] = useState(false);
  // Valeur entrée dans le champs "adresse"
  const [inputValue, setInputValue] = useState("");
  // Adresse sélectionnée par l'utilisateur
  const [selectedAddress, setSelectedAddress] = useState(null);
  // Adresses proposées en fonction de ce qu'a tapé l'utilisateur
  const [addressChoices, setAddressChoices] = useState([]);
  // True si les mots de passes sont affichés, false sinon
  const [showPasswords, setShowPasswords] = useState(null);
  // True si l'adresse mail est déjà utilisée dans le projet, false sinon
  const [emailAlreadyUsed, setEmailAlreadyUsed] = useState(null);

  const history = useHistory();

  var mapboxClient = mapboxSdk({accessToken: constants.mapboxToken});

  useEffect(() => {
    getAllRealAssoCreatedByAdmin()
    .then((result) => {
      console.log(result.data);
      setAssoChoices(result.data);
    })
  }, [])

  const phoneRegex = /(^$|^[0]{2}[3]{1}[2-3]{1}[\.\/ ]{1}[0-9]{3}([\.\/ ]{1}[0-9]{2}){3}$)/;
  const validationSchema = Yup.object().shape({
    phoneContact: Yup.string()
      .matches(phoneRegex, "Veuillez respecter le format demandé")
      .when("checkedAsso", {
        is: (checked) => !checked,
        then: Yup.string()
          .when("emailContact", {
            is: (email) => !email,
            then: Yup.string().required("Veuillez compléter au moins le numéro de téléphone ou l'adresse mail de contact")
          })
      }),
    emailContact: Yup.string()
      .when("checkedAsso", {
        is: (checked) => !checked,
        then: Yup.string()
          .when("phoneContact", {
            is: (phone) => !phone,
            then: Yup.string()
              .required("Veuillez compléter au moins le numéro de téléphone ou l'adresse mail de contact")
          })
      })
      .email("Veuillez indiquer une adresse mail valide"),
    email: Yup.string()
      .required("Veuillez indiquer votre adresse mail")
      .email("Veuillez indiquer une adresse mail valide"),
    password: Yup.string()
      .required("Veuillez créer un mot de passe"),
    password2: Yup.string()
      .required("Veuillez réécrire votre mot de passe")
      .oneOf([Yup.ref('password')], 'Les mots de passe doivent correspondre')
  }, [["phoneContact", 'emailContact']]);

  const {handleSubmit, control} = useForm({
    resolver: yupResolver(validationSchema),
    /*defaultValues: {
      name: "familles rurales",
      address: "adresse fictive",
      phone: "012345",
      password: "123456",
      password2: "123456",
      email: "jeromemaquoi@gmail.com",
      description: "description",
      website: ""
    }*/
  });

  /**
   * Gestion de l'envoi du formulaire une fois rempli
   * @param {*} data Données du formulaire sauvegardées dans la plateforme
   */
  const onSubmit = (data) => {
    //console.log(selectedAddress, nameAsso, checkedAsso);
    // Gestion des coordonnées de l'adresse postale
    if (typeof data.address === 'object') {
      data["latitude"] = data.address.geometry.coordinates[1];
      data["longitude"] = data.address.geometry.coordinates[0];
      data.address = data.address["place_name_fr"];
    }
    delete data.password2;

    isEmailUsed(data.email)
    .then(isUsed => {
      setEmailAlreadyUsed(isUsed);
      // Si l'adresse mail n'est pas encore utilisée, alors on continue
      if (!isUsed) {
        // Utilisateur a rentré un nouveau nom ==> l'association est créée
        if (nameAsso !== "" && selectedAddress !== null) {
          data["name"] = nameAsso;
          console.log("data nameAsso : ", data);
          signUp(data)
          .then((result) => {
            if (!result.statusCode) history.push(PATHS.LOGIN);
          })
          //signUp(nameAsso, data.address, data.phone, data.password, data.email, data.description, data.website);
        }
        // L'utilisateur a choisi un nom dans la liste
        // ==> mise à jour de l'association sélectionnée
        else if (checkedAsso !== null) {
          delete data.address;
          delete data.checkedAsso;
          delete data.description;
          delete data.emailContact;
          delete data.phoneContact;
          delete data.website;
          console.log("data checkedAsso : ", data, checkedAsso.profile.idProfile);
          registerAssoCreatedByAdmin(checkedAsso.profile.idProfile, data)
          .then((result) => {
            if (!result.statusCode) history.push(PATHS.LOGIN);
          });
        }
      }
    })
  }

  /**
   * Gestion de la sélection d'une association dans la liste des noms d'association déjà créés par un administrateur
   * @param {*} asso Association sélectionnée par l'utilisateur
   * @param {*} onChange Méthode permettant de modifier la valeur sélectionnée par l'utilisateur
   */
  const handleClickAsso = (asso, onChange) => () => {
    //console.log(checkedAsso);
    setNameAsso("");
    if (asso === checkedAsso) {
      setCheckedAsso(null);
      onChange(false);
    }
    else {
      setCheckedAsso(asso);
      onChange(true);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box>
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography component="h1" variant="h4">
                  Création d'un compte pour une association
                </Typography>
              </Grid>
              {assoChoices !== undefined && assoChoices.length > 0 ? (
                <Grid item>
                  <Typography>Si le nom de votre association se trouve dans la liste suivante, veuillez le sélectionner.</Typography>

                  <Controller
                    name="checkedAsso"
                    control={control}
                    render={({field: {onChange}}) => (
                      <List
                        sx={{width: '100%', maxHeight: 400, overflow: 'auto', bgcolor: 'background.paper'}}
                      >
                        {assoChoices.map((asso) => (
                          <ListItem
                            key={asso.profile.idProfile}
                            disablePadding
                          >
                            <ListItemButton
                              onClick={handleClickAsso(asso, onChange)}
                              dense
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={checkedAsso === asso}
                                  onChange={(e) => onChange(e.target.checked)}
                                />
                              </ListItemIcon>
                              <ListItemText id={asso.profile.idProfile} primary={asso.name}/>
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  />
                </Grid>
              ):(
                <Container>
                  <CircularProgress />
                </Container>
              )}

              { checkedAsso === null &&
                <Grid item container spacing={2} direction="column">
                  <Grid item>
                    <Typography component="h2" variant="h5">
                      Informations sur l'association
                    </Typography>
                    <Typography>Si votre association ne se retrouve pas dans la liste, veuillez remplir le formulaire suivant.</Typography>
                  </Grid>

                  <Grid item>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Nom de l'association"
                      error={checkedAsso === null && buttonClicked === true && nameAsso === ""}
                      helperText={checkedAsso === null && buttonClicked === true && nameAsso === "" ? "Veuillez renseigner le nom de votre association":null}
                      value={nameAsso}
                      onChange={(event) => setNameAsso(() => event.target.value)}
                    />
                  </Grid> 
                  <Grid item>
                    <Controller
                      name="address"
                      control={control}
                      defaultValue={""}
                      render={({field: {onChange, value}, fieldState: {error}}) => 
                        <Autocomplete
                          value={value}
                          onChange={(e, newValue) => {
                            // On enregistre le fait que l'utilisateur a cliqué sur une adresse ou a supprimé l'adresse sélectionnée
                            setSelectedAddress(newValue);
                            onChange(newValue);
                          }}
                          filterOptions={(x) => x}
                          inputValue={inputValue}
                          onInputChange={(event, newInput) => {
                            setInputValue(newInput);
                            if (newInput.length > 2){
                              mapboxClient.forwardGeocode({
                                query: newInput,
                                countries: ['fr', 'be'],
                                language: ['fr'],
                                autocomplete: true,
                                types: ["address", "postcode", "place", "locality"]
                              })
                              .send()
                              .then(response => {
                                setAddressChoices(response.body.features)
                              })
                            }
                          }}
                          freeSolo
                          options={addressChoices}
                          getOptionLabel={(option) => option["place_name_fr"] || ""}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              label="Adresse / code postal de l'association"
                              error={selectedAddress === null && buttonClicked === true}
                              helperText={selectedAddress === null && buttonClicked === true ? "Veuillez indiquer l'adresse de votre association": null}
                            />
                          }
                        />
                      }
                    />
                  </Grid>

                  <Grid item>

                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Typography>Veuillez renseigner au moins un champ parmi le numéro de contact et l'adresse mail de contact. Pour le numéro de téléphone, veuillez respecter le format suivant: 0032 456 12 34 56 (avec 0032 pour la Belgique et 0033 pour la France). Vous pouvez remplacer chaque espace par un point ou une barre oblique (ou slash), ce qui peut donner, par exemple, le numéro 0032/456.12.34.56</Typography>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <Controller
                          name="phoneContact"
                          control={control}
                          defaultValue=""
                          render={({field, fieldState: {error}}) => 
                            <TextField
                              {...field}
                              fullWidth
                              variant="outlined"
                              label="Numéro de téléphone de contact"
                              error={!!error}
                              helperText={error? error.message:null}
                            />
                          }
                        />
                      </Grid>
                      
                      <Grid item sm={6} xs={12}>
                        <Controller
                          name="emailContact"
                          control={control}
                          defaultValue=""
                          render={({field, fieldState: {error}}) => 
                            <TextField
                              {...field}
                              fullWidth
                              variant="outlined"
                              label="Adresse mail de contact"
                              error={!!error}
                              helperText={error? error.message:null}
                            />
                          }
                        />
                      </Grid>
                    </Grid>

                  </Grid>

                  <Grid item>
                    <Controller
                      name="website"
                      control={control}
                      defaultValue=""
                      render={({field, fieldState: {error}}) => 
                        <TextField
                          {...field}
                          fullWidth
                          variant="outlined"
                          label="Site web de l'association (optionnel)"
                          error={!!error}
                          helperText={error? error.message:null}
                        />
                      }
                    />
                  </Grid>

                  <Grid item>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      render={({field, fieldState: {error}}) => 
                        <TextField
                          {...field}
                          fullWidth
                          multiline
                          rows={4}
                          variant="outlined"
                          label="Description de l'association (optionnel)"
                          error={!!error}
                          helperText={error? error.message:null}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              }

              <Grid item>
                <Typography component="h2" variant="h5">
                  Création d'un compte
                </Typography>
              </Grid>

              <Grid item>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({field, fieldState: {error}}) => 
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Adresse mail"
                      error={!!error || emailAlreadyUsed}
                      helperText={error? (error.message):(emailAlreadyUsed?("Cette adresse mail est déjà associée à un compte. Veuillez en renseigner une autre."):(null))}
                    />
                  }
                />
              </Grid>

              <Grid item>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({field, fieldState: {error}}) => 
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Mot de passe"
                      type={showPasswords ? "text":"password"}
                      error={!!error}
                      helperText={error? error.message:null}
                    />
                  }
                />
              </Grid>
              <Grid item>
                <Controller
                  name="password2"
                  control={control}
                  defaultValue=""
                  render={({field, fieldState: {error}}) => 
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Tapez à nouveau votre mot de passe"
                      type={showPasswords ? "text":"password"}
                      error={!!error}
                      helperText={error? error.message:null}
                    />
                  }
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={<Checkbox checked={showPasswords} onChange={() => setShowPasswords(!showPasswords)}/>}
                  label="Afficher les mots de passe"
                />
              </Grid>
              
              {errorAuth && (
                <Typography color={styles.auth.error.color}>{errorAuth}</Typography>
              )}

            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={() => setButtonClicked(true)}
            >
              Créer mon compte
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href={PATHS.LOGIN} variant="body2">
                  Vous avez déjà un compte? Cliquez ici
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>
        {/*<Copyright sx={{ mt: 5 }} />*/}
      </Container>
    </ThemeProvider>
  );
}