import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Checkbox, CircularProgress, Container, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "../../navigation/AuthProvider";
import * as Yup from 'yup';
import { Country } from "../../common/enum";
import { FormGroup } from "@material-ui/core";

/**
 * Composant qui permet d'ajouter ou modifier un numéro important dans la plateforme. L'interface s'adapte en fonction de si c'est un ajout ou une modification mais globalement, c'est le même formulaire dans les deux cas.
 * @param {object} history Permet de gérer les routes empruntées par l'utilisateur en fonction de l'action qu'il effectue
 * @param {object} match Permet de récupérer l'url exacte où se trouve l'utilisateur pour l'instant
 * @returns Un formulaire d'ajout ou de modification d'un numéro important
 */
export default function AddEditImportantNumber({history, match}:any) {
  const {getOneImportantNumber, createImportantNumber, updateImportantNumber} = useContext(AuthContext);
  const {id} = match.params;
  const isAddMode = !id;

  const [importantNumber, setImportantNumber] = useState<any>();
  const [selectedCountries, setSelectedCountries] = useState<any>({
    "Belgique": false,
    "France": false
  });

  useEffect(() => {
    if (!isAddMode) {
      getOneImportantNumber(id)
      .then((result:any) => {
        setImportantNumber(result);

        let tmp:any = {
          "Belgique": false,
          "France": false
        };
        Object.keys(tmp).map(function(key, index) {
          result.countries.includes(key) ? tmp[key] = true : tmp[key] = false;
        });
        setSelectedCountries((prev:any) => tmp);
      })
    }
  }, []);

  /**
   * Gestion de la sélection d'un pays via une checkbox
   * @param event Evénement déclenché lorsqu'un utilisateur appuie sur une checkbox
   */
  const handleSelectedCountryChange = (event:any) => {
    setSelectedCountries({
      ...selectedCountries,
      [event.target.name]: event.target.checked
    });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Veuillez indiquer un titre"),
    phoneNumber: Yup.string()
      .required("Veuillez indiquer un numéro de téléphone"),
  })

  const {handleSubmit, control} = useForm({
    resolver: yupResolver(validationSchema)
  });

  /**
   * Gestion de l'envoi du formulaire une fois rempli
   * @param data Données du formulaire sauvegardées dans la plateforme
   */
  const onSubmit = (data:any) => {
    // Gestion des pays sélectionnés
    var countries: any = [];
    for (let el in selectedCountries) {
      if (selectedCountries[el] === true) countries.push(el);
    }
    data.countries = countries;
    console.log(data);

    isAddMode ? createOneImportantNumber(data) : updateOneImportantNumber(id, data);
  }

  /**
   * Création d'un numéro important
   * @param data Données de création du numéro
   */
  const createOneImportantNumber = async (data:any) => {
    const result = await createImportantNumber(data);
    if (!result.statusCode) history.push('/form/important-numbers');
  }

  /**
   * Modification d'un numéro important
   * @param idNumber Id du numéro à modifier
   * @param data Données de modification du numéro
   */
  const updateOneImportantNumber = async (idNumber:number, data:any) => {
    const result = await updateImportantNumber(idNumber, data);
    if (!result.statusCode) history.push('/form/important-numbers');
  }

  return (
    <Container maxWidth="xl">
      {importantNumber === undefined && !isAddMode ? (
        <CircularProgress />
      ) : (
        <Grid>

          <form onSubmit={handleSubmit(onSubmit)}>

            <Grid container direction="column" rowSpacing={2}>

              <Grid item>
                <Typography variant="h4">{isAddMode ? "Ajout d'un numéro important":"Modification d'un numéro important"}</Typography>
              </Grid>

              {/* Nom du numéro */}
              <Grid item>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={isAddMode ? "" : importantNumber.name}
                  render={({field, fieldState: {error}}) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Titre associé au numéro"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              {/* Numéro de téléphone */}
              <Grid item>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue={isAddMode ? "" : importantNumber.phoneNumber}
                  render={({field, fieldState: {error}}) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Numéro de téléphone"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              {/* Pays */}
              <Grid item>
                <FormControl>
                  <FormLabel>Pays</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      label="Belgique"
                      value={Country.belgium}
                      control={
                        <Checkbox
                          checked={selectedCountries[Country.belgium]}
                          onChange={handleSelectedCountryChange}
                          name={Country.belgium}
                        />
                      }
                    />
                    <FormControlLabel
                      label="France"
                      value={Country.france}
                      control={
                        <Checkbox
                          checked={selectedCountries[Country.france]}
                          onChange={handleSelectedCountryChange}
                          name={Country.france}
                        />
                      }
                    />
                  </FormGroup>

                  {/*<RadioGroup
                    value={selectedCountry}
                    onChange={(e:any) => setSelectedCountry(e.target.value)}
                  >
                    <FormControlLabel
                      value={Country.belgium}
                      label={"Belgique"}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={Country.france}
                      label={"France"}
                      control={<Radio />}
                    />
                  </RadioGroup>*/}
                </FormControl>
              </Grid>

              {/* Description */}
              <Grid item>
                <Controller
                  name="description"
                  control={control}
                  defaultValue={isAddMode ? "" : importantNumber.description !== null ? importantNumber.description : ""}
                  render={({field, fieldState: {error}}) => (
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      minRows={2}
                      maxRows={8}
                      variant="outlined"
                      label="Description du numéro (optionnel)"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item>
                <Controller
                  name="website"
                  control={control}
                  defaultValue={isAddMode ? "" : importantNumber.website !== null ? importantNumber.website : ""}
                  render={({field, fieldState: {error}}) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Site web (optionnel)"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                >{isAddMode ? "Ajouter un numéro" : "Terminer les modifications"}</Button>
              </Grid>

            </Grid>

          </form>

        </Grid>
      )}
    </Container>
  )
}