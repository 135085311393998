import { Container } from "@mui/material";
import { Switch } from "react-router-dom";
import ProtectedRoute from "../../_helpers/ProtectedRoutes";
import AddEditLocalService from "./AddEditLocalService";
import ListServices from "./ListServices";

/**
 * Gestion des routes du composant relatif aux services locaux. Chaque route est géreée par un composant particulier
 * @param match Permet de récupérer l'url exact où se trouve l'utilisateur en ce moment
 * @returns Un Container contenant les différentes routes possibles
 */
export default function LocalServicesScreen({match}:any) {
  const {path} = match;

  return (
    <Container maxWidth="xl">
      <Switch>
        <ProtectedRoute exact path={path} component={ListServices} />
        <ProtectedRoute path={`${path}/edit/:id`} component={AddEditLocalService} />
        <ProtectedRoute path={`${path}/add`} component={AddEditLocalService} />
      </Switch>
    </Container>
  )
}