import { Edit, Delete } from "@mui/icons-material";
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import { DataGrid, frFR } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../../navigation/AuthProvider";

/**
 * Composant qui affiche une liste de numéros importants dans un DataGrid
 * @param match Permet de récupérer l'url exacte où se trouve l'utilisateur en ce moment
 * @returns Un Container dans lequel se trouve un DataGrid. Chaque colonne est un attribut d'un numéro important et chaque ligne est un numéro important
 */
export default function ListImportantNumbers({match}:any) {
  const {path} = match;
  const history = useHistory();
  const {getAllImportantNumbers, deleteImportantNumber} = useContext(AuthContext);

  const [importantNumbers, setImportantNumbers] = useState<any>();
  const [deletedNumber, setDeletedNumber] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  
  /**
   * Récupération de tous les numéros importants présents dans la plateforme
   */
  const getImportantNumbers = async () => {
    getAllImportantNumbers()
    .then((response:any) => {
      //console.log(response);
      setImportantNumbers(response);
    })
  }

  useEffect(() => {
    getImportantNumbers();
  }, []);

  useEffect(() => {
    setOpenDialog(true);
  }, [deletedNumber]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  return (
    <Container maxWidth="xl" style={{flexGrow: 1, display: "flex", width: '100%', flexDirection: "column"}}>
      <Typography variant="h4">Gestion des numéros importants</Typography>
      <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">Ajouter un numéro</Link>

      {importantNumbers && (
        <DataGrid
          autoHeight
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(r:any) => r.id}
          columns={[
            {field: 'name', minWidth: 130, flex: 1, headerName: "Nom"},
            {field: 'phoneNumber', minWidth: 150, flex: 1, headerName: "Numéro de téléphone"},
            {field: 'countries', minWidth: 150, flex: 1, headerName: "Pays"},
            {field: ' ', type: 'actions', flex: 1, minWidth: 200, renderCell: (params:any) => (
              <Grid container direction="row" justifyContent="space-evenly">
                <Button
                  variant="outlined"
                  startIcon={<Edit />}
                  onClick={() => {
                    history.push(`${path}/edit/${params.id}`);
                  }}
                >Editer</Button>
                <Button
                  variant="outlined"
                  startIcon={<Delete />}
                  color='error'
                  onClick={() => {
                    //console.log(params.row);
                    setDeletedNumber(params.row);
                  }}
                >Supprimer</Button>
              </Grid>
            )}
          ]}
          rows={importantNumbers}
        />
      )}

      {deletedNumber && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle>Confirmation de suppression</DialogTitle>
          <DialogContent>
            <DialogContentText>Voulez-vous vraiment supprimer le numéro de "{deletedNumber.name}" ?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleCloseDialog}
            >Non</Button>
            <Button
              variant="contained"
              onClick={() => {
                deleteImportantNumber(deletedNumber.id)
                .then((result: any) => {
                  handleCloseDialog();
                  getImportantNumbers();
                })
              }}
            >Oui</Button>
          </DialogActions>
        </Dialog>
      )}

    </Container>
  )
}