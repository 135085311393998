import {createTheme} from '@mui/material/styles';
import { Container, TextField, Typography, Button, Grid } from '@mui/material';
import React, { useContext } from 'react';
import { ThemeProvider } from '@mui/styles';
import { Box } from '@mui/system';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AuthContext } from '../../navigation/AuthProvider';
import { styles } from '../../common/style';

const theme = createTheme();

/**
 * Fonction qui permet de demander une réinitialisation de son mot de passe par adresse mail
 * @returns Une interface reprenant un petit formulaire où l'utilisateur doit rentrer son adresse mail pour demander un mail de réinitialisation
 */
export default function ForgotPassword() {
  const {forgotPassword, errorAuth, message} = useContext(AuthContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Veuillez entrer votre adresse mail")
  })
  const {handleSubmit, control} = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data) => {
    forgotPassword(data.email);
  }
  
  /*const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data.get('email'));
  }*/

  return(
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container maxWidth="lg" direction="column" rowSpacing={2}>
              <Grid item>
                <Typography variant="h4">Modification de mon mot de passe</Typography>
              </Grid>
              <Grid item>
                <Typography>Veuillez inscrire votre adresse mail. Vous recevrez un mail vous permettant de mettre à jour votre mot de passe.</Typography>
              </Grid>
              <Grid item>
                <Controller
                  name="email"
                  control={control}
                  render={({field, fieldState: {error}}) =>
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Votre adresse mail"
                      error={!!error}
                      helperText={error? error.message:null}
                    />
                  }
                />
              </Grid>

              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  Modifier mon mot de passe
                </Button>
              </Grid>

              {errorAuth && !message && (
                <Grid item>
                  <Typography color={styles.auth.error.color}>{errorAuth}</Typography>
                </Grid>
              )}

              {message && !errorAuth && (
                <Grid item>
                  <Typography color={styles.auth.message.color}>{message}</Typography>
                </Grid>
              )}

            </Grid>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  )
}