/** Rôle d'un utilisateur sur la plateforme qui lui donne accès à plus ou moins de fonctionnalités */
export enum Role {
  USER = "user",
  ADMIN = "admin",
  SUPERADMIN = "superadmin",
  TESTER = "tester"
}

/** Fréquence en secondes utilisée pour la récurrence d'une activité */
export enum Frequency {
  WEEK = 604800,
  TWOWEEKS = 1209600,
  MONTH = 2592000
}

/** Type de méthode d'une requête REST */
export enum TypeMethod {
  get = "GET",
  post = "POST",
  put = "PUT",
  delete = "DELETE"
}

/** Type de service local */
export enum TypeCategory {
  institution = "institution",
  annualDirectory = "annuaire"
}

/** Pays concerné par une activité, un service local ou un numéro important */
export enum Country {
  belgium = "Belgique",
  france = "France"
}

/** Chemin d'url */
export enum PATHS {
  LOGIN = "/auth/login"
}