import React, { useContext, useEffect, useRef, useState } from 'react';
import {Container, Typography, Grid, TextField, CircularProgress, Button} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AuthContext } from '../../navigation/AuthProvider';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Image from 'mui-image';
import { constants } from '../../common/constants';
import Upload from '@mui/icons-material/Upload';
import { Delete } from '@mui/icons-material';

const Input = styled('input')({
  display: 'none'
})

/**
 * Composant qui permet d'ajouter ou modifier une thématique ou un centre d'intérêt dans la plateforme. L'interface s'adapte en fonction de si c'est un ajout ou une modification mais globalement, c'est le même formulaire dans les deux cas
 * @param history Permet de gérer les routes empruntées par l'utilisateur en fonction de l'action qu'il effectue
 * @param match Permet de récupérer l'url exacte où se trouve l'utilisateur pour l'instant
 * @returns Une formulaire d'ajout ou de modification d'un numéro important
 */
export default function AddEdit({history, match}:any) {
  const {getOneTheme, updateTheme, createTheme, getOneTag, updateTag, createTag} = useContext(AuthContext);
  const {path} = match;
  const {id} = match.params;
  const isAddMode = !id;
  const form = useRef(null);

  const [data, setData] = useState<any>();
  const [whichData, setWhichData] = useState<string>();
  const [newIcon, setNewIcon] = useState<any>(null);
  const [fileToSend, setFileToSend] = useState<any>(null);
  const [removeIcon, setRemoveIcon] = useState<boolean>(false);

  useEffect(() => {
    if (path.includes("themes")) {
      setWhichData("theme");
      if (!isAddMode) {
        getOneTheme(id)
        .then((result:any) => {
          setData(result);
          setWhichData("theme");
        })
      }
    } else if (path.includes("interests")) {
      setWhichData("interest");
      if (!isAddMode) {
        getOneTag(id)
        .then((result:any) => {
          setData(result);
          setWhichData("interest");
          console.log(constants.url + "/local-file/tag-icon/" + result.icon.filename);
        })
      }
    }
  }, [])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Veuillez donner un nom à votre thématique"),
    description: Yup.string()
      .required("Veuillez décrire en quelques mots votre thématique")
  })

  // functions to build form returned by useForm() hook
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validationSchema)
  });

  /**
   * Gestion de l'envoi du formulaire de création ou modification d'une thématique ou d'un centre d'intérêts
   * @param data Données du formulaire sauvegardées dans la plateforme 
   * @returns Le résultat de la création ou modification d'une thématique ou centre d'intérêts
   */
  const onSubmit = (data:any) => {
    let formData = new FormData();
    if (fileToSend !== null) formData.append("file", fileToSend);
    formData.append("name", data.name);
    formData.append("description", data.description);
    if (newIcon === null) formData.append("removeIcon", "true");

    if (isAddMode) {
      if (whichData === "theme") return createOneTheme(formData);
      else if (whichData === "interest") return createOneInterest(formData);
    } else {
      if (whichData === "theme") return updateOneTheme(id, formData);
      else if (whichData === "interest") return updateOneInterest(id, formData);
    }
  }

  /**
   * Création d'une thématique
   * @param data Données de création d'une thématique
   * @returns Le résulta de la création de la thématique
   */
  const createOneTheme = (data:any) => {
    return createTheme(data)
      .then(() => {
        history.push('/form/themes');
      })
  }

  /**
   * Modification d'une thématique
   * @param idTheme Id de la thématique à modifier
   * @param data Données de modification de la thématique
   * @returns Le résultat de la modification de la thématique
   */
  const updateOneTheme = (idTheme:number, data:any) => {
    return updateTheme(idTheme, data)
      .then(() => {
        history.push('/form/themes');
      })
  }

  /**
   * Création d'un centre d'intérêts
   * @param data Données de création d'un centre d'intérêts
   * @returns Le résultat de la création du centre d'intérêts
   */
  const createOneInterest = (data: any) => {
    return createTag(data)
    .then(() => history.push('/form/interests'))
  }

  /**
   * Modification d'un centre d'intérêts
   * @param idInterest Id du centre d'intérêts à modifier
   * @param data Données de modification du centre d'intérêts
   * @returns Le résultat de la modification du centre d'intérêts
   */
  const updateOneInterest = (idInterest: number, data:any) => {
    return updateTag(idInterest, data)
    .then(() => {
      history.push('/form/interests')
    })
  }

  /**
   * Gestion de l'upload d'une image pour une thématique
   * @param event Evénement déclenché lorsque l'utilisateur sélectionne une image à ajouter sur la plateforme
   */
  const uploadImage = (event:any) => {
    let file = event.target.files[0];
    setFileToSend(file);
    const reader = new FileReader();
    let url = reader.readAsDataURL(file);
    
    reader.onloadend = function(e) {
      setNewIcon(reader.result);
    }
  }

  const removeImage = () => {
    setRemoveIcon(true);
  }

  return (
    <Container maxWidth="lg">
      {data === undefined && !isAddMode ? (
        <CircularProgress />
      ) : (
        <Container>
          <Typography variant="h4">{isAddMode?(whichData === "theme" ? ("Ajout d'une thématique"):("Ajout d'un centre d'intérêt")):(whichData === "theme" ? ("Modification d'une thématique"):("Modification d'un centre d'intérêt"))}</Typography>

          <Typography>{isAddMode ? (whichData === "theme" ? "N'oubliez pas de cliquer sur 'Ajouter une thématique' pour que les informations soient enregistrées.":"N'oubliez pas de cliquer sur 'Ajouter un centre d'intérêt' pour que les informations soient enregistrées."):("N'oubliez pas de cliquer sur 'Terminer les modifications' pour que les informations soient enregistrées.")}</Typography>

          <form onSubmit={handleSubmit(onSubmit)} ref={form}>
            <Grid container direction="column" rowSpacing={2}>
              
              {/* Nom de la thématique */}
              <Grid item>
                <Controller
                  render={({field, fieldState: {error}}) => 
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label={whichData === "theme" ? ("Nom de la thématique"):("Nom du centre d'intérêt")}
                      error={!!error}
                      helperText={error? error.message: null}
                    />
                  }
                  name="name"
                  control={control}
                  defaultValue={isAddMode?(""):(data?.name)}
                />
              </Grid>

              {/* Description de la thématique */}
              <Grid item>
                <Controller
                  render={({field, fieldState: {error}}) => 
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label={whichData === "theme" ? ("Description de la thématique"):("Description du centre d'intérêt")}
                      error={!!error}
                      helperText={error? error.message: null}
                    />
                  }
                  name="description"
                  control={control}
                  defaultValue={isAddMode?(""):(data?.description)}
                />
              </Grid>

              <Grid item>
                <Grid item>
                  <Typography variant='h5'>Gestion de l'icône</Typography>
                </Grid>

                <Grid item container direction="row">
                  <Grid item sx={{p: 2}}>

                    <Grid item sx={{p: 1}}>
                      <label htmlFor='button-upload'>
                        <Input accept='image/*' id="button-upload" type="file" onChange={uploadImage}/>
                        <Button variant="contained" component="span" startIcon={<Upload />}>{isAddMode ? "Ajouter une icone":"Modifier l'icone"}</Button>
                      </label>
                    </Grid>

                    <Grid item sx={{p: 1}}>
                      <Button variant="contained" color='error' startIcon={<Delete />} onClick={removeImage}>Supprimer l'icone</Button>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      p: 2,
                      border: '1px dashed grey'
                    }}
                  >
                    { // Si une icone est déjà définie, on l'affiche
                    data !== undefined && data.icon !== undefined && data.icon !== null && newIcon === null && removeIcon === false ? (
                      <Image
                        src={path.includes("themes") ? (constants.url + "/local-file/tag-theme/" + data.icon.filename):(constants.url + "/local-file/tag-icon/" + data.icon.filename)}
                        height="200px"
                        width="200px"
                        showLoading={true}
                        duration={0}
                      />
                    ) : (
                      // Si l'utilisateur upload une nouvelle icone, on l'affiche
                      newIcon !== null ? (
                        <Image
                          src={newIcon}
                          height="200px"
                          width="200px"
                          showLoading={true}
                          duration={0}
                        />
                      ):(
                        <Typography>Il n'y a pour le moment pas d'icône associée à cet élément.</Typography>
                      )
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                >{isAddMode?(whichData === "theme" ? "Ajouter une thématique":"Ajouter un centre d'intérêt"):("Terminer les modifications")}</Button>
              </Grid>

            </Grid>
          </form>
        </Container>
      )}
    </Container>
  )
}