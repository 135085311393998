import { Container} from "@mui/material";
import { Switch } from "react-router-dom";
import ProtectedRoute from "../../_helpers/ProtectedRoutes";
import AddEdit from "../themes-interests/AddEdit";
import ListThemesOrInterests from "../themes-interests/ListThemesOrInterests";

/**
 * Gestion des routes du composant relatif aux centres d'intérêts. Chaque route est gérée par un composant particulier
 * @param match Permet de récupérer l'url exacte où se trouve l'utilisateur en ce moment
 * @returns Un Container contenant les différentes routes possibles
 */
export default function InterestsScreen({ match }:any) {
  const {path} = match;

  return (
    <Container maxWidth="xl">
      <Switch>
        <ProtectedRoute exact path={path} component={ListThemesOrInterests}/>
        <ProtectedRoute path={`${path}/add`} component={AddEdit}/>
        <ProtectedRoute path={`${path}/edit/:id`} component={AddEdit}/>
      </Switch>
    </Container>
  )
}