import React from 'react';
import { Switch } from 'react-router-dom';

import { List } from './List';
import { AddEditActivity } from './AddEditActivity';
import ProtectedRoute from '../../_helpers/ProtectedRoutes';
import { Container } from '@mui/material';

/**
 * Gestion des routes du composant activities. Chaque route est gérée par un composant particulier
 * @param {object} match Permet de récupérer le chemin de l'url
 * @returns Un Container contenant les différentes routes possibles
 */
export default function Activities({ match }) {
  const { path } = match;
  
  return (
    <Container maxWidth="xl">
      <Switch>
        <ProtectedRoute exact path={path} component={List}/>
        <ProtectedRoute path={`${path}/add`} component={AddEditActivity} />
        <ProtectedRoute path={`${path}/edit/:id`} component={AddEditActivity} />
      </Switch>
    </Container>
  );
}