import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { AuthContext } from '../navigation/AuthProvider';
import { DataGrid, frFR } from '@mui/x-data-grid';
import { Role } from '../common/enum';

/**
 * Composant qui affiche la liste des comptes d'association pas encore validés par un administrateur. Les comptes sont affichés dans un DataGrid
 * @returns Un Containern dans lequel se trouve un DataGrid. Chaque colonne est un attribut d'un compte et chaque ligne un compte
 */
export default function ActivateAccounts() {
  const {getPendingAccounts, accountActivation, getRealPendingAccounts, hasRole} = useContext(AuthContext);
  const [pendingAccounts, setPendingAccounts] = useState();

  // Compte qui est accepté ou refusé par un admin
  const [confirmationAccount, setConfirmationAccount] = useState();
  const [typeConfirmation, setTypeConfirmation] = useState();

  // Utilisé pour ouvrir ou fermer la fenêtre de confirmation d'acceptation ou de refus d'un compte
  const [openDialog, setOpenDialog] = useState();

  /**
   * Récupération de tous les comptes d'association réels pas encore validés par un administrateur. Si l'utilisateur est un superadmin, alors cette liste reprend également les comptes test
   */
  const getAccounts = () => {
    if (hasRole(Role.SUPERADMIN)) {
      getPendingAccounts()
      .then((response) => {
        console.log(response);
        setPendingAccounts(response);
      });
    } else {
      getRealPendingAccounts()
      .then(response => setPendingAccounts(response));
    }
  }

  useEffect(() => {
    getAccounts();
  }, [])

  useEffect(() => {
    handleClickOpenDialog();
  }, [confirmationAccount])

  // Récupération de l'adresse postale des données pour l'afficher
  // dans la grille
  const getAddress = (params) => {
    return params.row.profile.email;
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  }

  /**
   * Rendu des boutons d'acceptation ou de refus d'un compte d'association
   * @param {any} params La ligne du DataGrid concernée, qui représente un compte
   */
  const renderActions = (params) => (
    <Container>
      <Button
        variant="contained"
        onClick={() => {
          setTypeConfirmation("accepted");
          setConfirmationAccount(params.row);
        }}
      >Accepter</Button>
      <Button
        variant="contained"
        onClick={() => {
          setTypeConfirmation("refused");
          setConfirmationAccount(params.row);
        }}
      >Refuser</Button>
    </Container>
  )

  return(
    <Container maxWidth="xl">
      <Container style={{flexGrow: 1, display: "flex", width: '100%', flexDirection: "column"}}>
        <Typography variant="h4">Activation des nouveaux comptes</Typography>

        {pendingAccounts && (
          <DataGrid
            autoHeight
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            getRowId={(r) => r.profile.idProfile}
            rows={pendingAccounts}
            columns={[
              {field: 'name', headerName: "Nom de l'association", minWidth: 400},
              {field: 'email', headerName: "Adresse mail", minWidth: 200, valueGetter: getAddress},
              {field: 'address', headerName: "Adresse", minWidth: 250},
              {field: ' ', type: 'actions', width: 200, renderCell: renderActions}
            ]}
          />
        )}

        {confirmationAccount && (
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
          >
            <DialogTitle>
              {typeConfirmation === "accepted" ? ("Confirmation d'acceptation"):("Confirmation de refus")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {typeConfirmation === "accepted" ? ("Voulez-vous vraiment accepter le compte "):("Voulez-vous vraiment refuser le compte ")}
                {confirmationAccount.profile.email} ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => {
                  setConfirmationAccount();
                  setTypeConfirmation();
                  handleCloseDialog();
                }}
              >Non</Button>
              <Button
                variant="outlined"
                onClick={() => {
                  const body = {
                    "adminActivation": typeConfirmation
                  }
                  accountActivation(confirmationAccount.profile.idProfile, body)
                  .then(() => {
                    // Reset des hooks
                    setConfirmationAccount();
                    setTypeConfirmation();
                    // Fermeture du Dialog
                    handleCloseDialog();
                    // MAJ des comptes affichés dans le tableau
                    getAccounts();
                  })
                }}
              >Oui</Button>
            </DialogActions>
          </Dialog>
        )}

      </Container>
    </Container>
  )
}