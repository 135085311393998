import { Container } from "@mui/material";
import {Switch} from 'react-router-dom';
import ProtectedRoute from "../../_helpers/ProtectedRoutes";
import AssoByAdminList from "./AssoByAdminList";
import AddEditAsso from './AddEditAsso';

/**
 * Gestion des routes du composant relatif aux associations. Chaque route est gérée par un composant particulier
 * @param {object} match Permet de récupérer l'url exacte où se trouve l'utilisateur en ce moment
 * @returns 
 */
export default function Asso({match}:any) {
  const {path} = match;

  return (
    <Container maxWidth="xl">
      <Switch>
        <ProtectedRoute exact path={path} component={AssoByAdminList}/>
        <ProtectedRoute path={`${path}/add`} component={AddEditAsso} />
        <ProtectedRoute path={`${path}/edit/:id`} component={AddEditAsso} />
      </Switch>
    </Container>
  )
}