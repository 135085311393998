import { Container, Grid, Typography, TextField, Button, FormGroup, FormControlLabel, Checkbox, Autocomplete, CircularProgress } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../navigation/AuthProvider";
import { Role } from "../../common/enum";
import mapboxSdk from '@mapbox/mapbox-sdk/services/geocoding';
import { constants } from '../../common/constants';
import { Association } from "../../common/types";

/**
 * Composant permettant de créer ou modifier une association. L'interface s'adapte en fonction de si c'est une création ou une modification, mais globalement, c'est le même formulaire pour les deux.
 * @param {object} history Permet de gérer les routes empruntées par l'utilisateur en fonction de l'action qu'il effectue
 * @param {object} match Permet de récupérer l'url exacte où se trouve l'utilisateur pour l'instant
 * @returns Un formulaire d'ajout ou de modification d'une association
 */
export default function CreateAsso({history, match}:any) {
  const {createAssoByAdmin, hasRole, getAssoDetails, getCurrentUser, updateAssociation} = useContext(AuthContext);
  const { id } = match.params;
  const isAddMode = !id;

  const [asso, setAsso] = useState<Association>();
  // True si l'association est un test, false sinon
  const [checked, setChecked] = useState(false);
  // True si l'utilisateur a cliqué sur "créer mon compte", false sinon
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  // Valeur entrée dans le champs "adresse"
  const [inputValue, setInputValue] = useState<string>("");
  // Adresse sélectionnée par l'utilisateur
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  // Adresses proposées en fonction de ce qu'a tapé l'utilisateur
  const [addressChoices, setAddressChoices] = useState<any>([]);

  var mapboxClient = mapboxSdk({accessToken: constants.mapboxToken});

  useEffect(() => {
    // Mise à jour de l'association
    if (!isAddMode) {
      getAssoDetails(id)
      .then((data:any) => {
        console.log(data.data);
        setAsso(data.data);
        setInputValue(data.data.address);
        if (data.data.address !== "") setSelectedAddress(data.data.address);
        setChecked(data.data.isTest);
      })
    }
  }, []);

  const phoneRegex = /(^$|^[0]{2}[3]{1}[2-3]{1}[\.\/ ]{1}[0-9]{3}([\.\/ ]{1}[0-9]{2}){3}$)/;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Veuillez donner un nom à l'association"),
    phoneContact: Yup.string()
      .matches(phoneRegex, "Veuillez respecter le format demandé")
      .when("emailContact", {
        is: (email:string) => !email,
        then: Yup.string().required("Veuillez compléter au moins le numéro de téléphone ou l'adresse mail de contact")
      }),
    emailContact: Yup.string()
      .when("phoneContact", {
        is: (phone:string) => !phone,
        then: Yup.string()
          .required("Veuillez compléter au moins le numéro de téléphone ou l'adresse mail de contact")
      })
      .email("Veuillez indiquer une adresse mail valide")
  }, [["phoneContact", 'emailContact']]);

  const {handleSubmit, control} = useForm({
    resolver: yupResolver(validationSchema)
  });

  /**
   * Gestion de l'envoi du formulaire une fois rempli
   * @param data Données du formulaire sauvegardées dans la plateforme
   */
  const onSubmit = async (data:any) => {
    if (selectedAddress !== null) {
      // Préparation des données avant l'appel à l'API
      if (checked) data["isTest"] = true;
      else data["isTest"] = false;
      if (typeof data.address === 'object') {
        data["latitude"] = data.address.geometry.coordinates[1];
        data["longitude"] = data.address.geometry.coordinates[0];
        data["address"] = data.address["place_name_fr"];
      }

      console.log(data);

      isAddMode ? createAsso(data) : updateAsso(id, data)
    }
  }

  /**
   * Création d'une association
   * @param data Données de création de l'association
   */
  const createAsso = async (data:object) => {
    const result = await createAssoByAdmin(data);
    if (!result.statusCode) history.push('/form/accounts');
  }

  /**
   * Modification d'une association
   * @param idAsso Id de l'association
   * @param data Données de modification de l'association
   */
  const updateAsso = async (idAsso:number, data:object) => {
    const update = await updateAssociation(idAsso, data);
    if (!update.statusCode) history.push("/form/accounts");
  }

  return (
    <Container maxWidth="xl">
      {asso === undefined && !isAddMode ? (
        <CircularProgress />
      ) : (
        <Grid>
          <Typography variant="h4">{isAddMode ? "Création d'une nouvelle association" : "Modification d'une association"}</Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" rowSpacing={2}>

              {hasRole(Role.SUPERADMIN) && (
                <Grid item>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(event) => setChecked(event.target.checked)}
                        />
                      }
                      label="Association fictive"
                    />
                  </FormGroup>
                </Grid>
              )}

              <Grid item>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={isAddMode ? ("") : (asso?.name)}
                  render={({field, fieldState: {error}}) => 
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label={"Nom de l'association"}
                      error={!!error}
                      helperText={error? error.message: null}
                    />
                  }
                />
              </Grid>


              <Grid item>
                <Controller
                  name="address"
                  control={control}
                  defaultValue={isAddMode ? ("") : (asso?.address)}
                  render={({field: {onChange, value}, fieldState: {error}}) => 
                    <Autocomplete
                      value={value}
                      onChange={(e, newValue) => {
                        // On enregistre le fait que l'utilisateur a cliqué sur une adresse ou a supprimé l'adresse sélectionnée
                        setSelectedAddress(newValue);
                        onChange(newValue);
                      }}
                      filterOptions={(x) => x}
                      inputValue={inputValue}
                      onInputChange={(event, newInput) => {
                        setInputValue(newInput);
                        if (newInput.length > 2){
                          mapboxClient.forwardGeocode({
                            query: newInput,
                            countries: ['fr', 'be'],
                            language: ['fr'],
                            autocomplete: true,
                            types: ["address", "postcode", "place", "locality"]
                          })
                          .send()
                          .then((response:any) => {
                            setAddressChoices(response.body.features)
                          })
                        }
                      }}
                      freeSolo
                      options={addressChoices}
                      getOptionLabel={(option:any) => option["place_name_fr"] || ""}
                      isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          label="Adresse / code postal de l'association"
                          error={selectedAddress === null && buttonClicked === true}
                          helperText={selectedAddress === null && buttonClicked === true ? "Veuillez indiquer l'adresse ou le code postal de l'association puis le ou la sélectionner dans la liste proposée": null}
                        />
                      }
                    />
                  }
                />
              </Grid>

              <Grid item container spacing={2}>

                <Grid item xs={12}>
                  <Typography>Veuillez renseigner au moins un champ parmi le numéro de contact et l'adresse mail de contact. Pour le numéro de téléphone, veuillez respecter le format suivant: 0032 456 12 34 56 (avec 0032 pour la Belgique et 0033 pour la France). Vous pouvez remplacer chaque espace par un point ou une barre oblique (ou slash), ce qui peut donner, par exemple, le numéro 0032/456.12.34.56</Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="phoneContact"
                    control={control}
                    defaultValue={isAddMode ? ("") : (asso?.phoneContact)}
                    render={({field, fieldState: {error}}) => 
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        label="Numéro de téléphone de contact"
                        error={!!error}
                        helperText={error? error.message:null}
                      />
                    }
                  />
                </Grid>
                
                <Grid item sm={6} xs={12}>
                  <Controller
                    name="emailContact"
                    control={control}
                    defaultValue={isAddMode ? ("") : (asso?.emailContact)}
                    render={({field, fieldState: {error}}) => 
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        label="Adresse mail de contact"
                        error={!!error}
                        helperText={error? error.message:null}
                      />
                    }
                  />
                </Grid>

              </Grid>

              <Grid item>
                <Controller
                  name="website"
                  control={control}
                  defaultValue={isAddMode ? ("") : (asso?.website)}
                  render={({field, fieldState: {error}}) => 
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Site web de l'association (optionnel)"
                      error={!!error}
                      helperText={error? error.message:null}
                    />
                  }
                />
              </Grid>

              <Grid item>
                <Controller
                  name="description"
                  control={control}
                  defaultValue={isAddMode ? ("") : (asso?.description)}
                  render={({field, fieldState: {error}}) => 
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                      label="Description de l'association (optionnel)"
                    />
                  }
                />
              </Grid>


              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => setButtonClicked(true)}
                >{isAddMode ? "Ajouter une association" : "Terminer les modifications"}</Button>
              </Grid>

            </Grid>
          </form>
        </Grid>
      )}
    </Container>
  )
}