import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import './App.css';
import { AuthContext, AuthProvider } from './navigation/AuthProvider';
import {SnackbarProvider} from 'notistack';
import HomeNavigator from './navigation/HomeNavigator';
import AuthNavigator from './navigation/AuthNavigator';

/**
 * Composant de base de l'application. Il définit les routes de tous les autres composants. C'est sur ce composant que l'on arrive au début de l'utilisation de l'application
 * @returns 
 */
function App() {
  document.title = "Smart Socialized Living";
  // On enlève tous les logs lorsqu'on est en production
  if (process.env.NODE_ENV !== "development"){
    console.log = () => {};
  }

  return (
    <Router>
      <SnackbarProvider maxSnack={3}>
        <AuthProvider>
          <Navigation />
        </AuthProvider>
      </SnackbarProvider>
    </Router>
  );
}
/**
 * Composant qui gère la navigation en fonction de si l'utilisateur est connecté ou non. S'il est connecté, il le renvoie vers l'application, sinon il le renvoie vers le formulaire de connexion
 * @returns Un Switch qui redirige l'utilisateur
 */
function Navigation() {
  const {userLoggedIn} = useContext(AuthContext);

  return (
    <>
      {userLoggedIn === true ? (
        <Switch>
          <Route path="/form" component={HomeNavigator} />
          <Redirect from="*" to="/form" />
        </Switch>
      ):(
        <Switch>
          <Route path="/auth" component={AuthNavigator} />
          <Redirect from="*" to="/auth/login" />
        </Switch>
      )}
    </>
  )
}

export default App;
