import { Container } from "@mui/material";
import { Switch } from "react-router-dom";
import ProtectedRoute from "../../_helpers/ProtectedRoutes";
import AddEditImportantNumber from "./AddEditImportantNumber";
import ListImportantNumbers from "./ListImportantNumbers";

/**
 * Gestion des routes du composant relatif aux numéros importants. Chaque route est gérée par un composant particulier
 * @param match Permet de récupérer l'url exacte où se trouve l'utilisateur en ce moment
 * @returns Un Container contenant les différentes routes possibles
 */
export default function ImportantNumbersService({match}:any) {
  const {path} = match;

  return (
    <Container maxWidth="xl">
      <Switch>
        <ProtectedRoute exact path={path} component={ListImportantNumbers} />
        <ProtectedRoute path={`${path}/edit/:id`} component={AddEditImportantNumber} />
        <ProtectedRoute path={`${path}/add`} component={AddEditImportantNumber} />
      </Switch>
    </Container>
  )
}