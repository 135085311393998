import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button, Grid } from "@mui/material";
import { Link, useHistory } from 'react-router-dom';
import { DataGrid, frFR } from '@mui/x-data-grid';
import { AuthContext } from '../../navigation/AuthProvider';
import { Role } from '../../common/enum';
import { Delete, Edit } from '@mui/icons-material';

/**
 * Interface qui affiche une liste d'associations dans un DataGrid
 * @param {object} match Permet de récupérer l'url exacte où se trouve l'utilisateur en ce moment
 * @returns Un Container dans lequel se trouve un DataGrid. Chaque colonne est un attribut d'une association et chaque ligne une association
 */
export default function AssoByAdminList({ match }) {
  const {path} = match;
  const {getAllAssoCreatedByAdmin, deleteAsso, updateAsso, getAllRealAssoCreatedByAdmin, hasRole} = useContext(AuthContext);
  const history = useHistory();

  const [allAsso, setAllAsso] = useState();
  const [deletedAsso, setDeletedAsso] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  const getAsso = () => {
    if (hasRole(Role.SUPERADMIN)) {
      getAllAssoCreatedByAdmin()
      .then((result) => {
        console.log(result.data);
        setAllAsso(result.data);
      })
    } else {
      getAllRealAssoCreatedByAdmin()
      .then(result => setAllAsso(result.data))
    }
  }

  useEffect(() => {
    getAsso();
  }, []);

  useEffect(() => {
    setOpenDialog(true);
  }, [deletedAsso]);

  const getNameAsso = (params) => {
    return params.row.name;
  }

  const getAddressAsso = (params) => {
    return params.row.address;
  }

  return (
    <Container maxWidth="xl" style={{flexGrow: 1, display: "flex", width: '100%', flexDirection: "column"}}>
      <Typography variant="h4">Gestion de la création des associations</Typography>
      <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">Créer une association</Link>
      {allAsso && (
        <DataGrid
          autoHeight
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(r) => r.profile.idProfile}
          rows={allAsso}
          loading={allAsso === undefined}
          columns={[
            {field: 'name', minWidth: 150, flex: 3, headerName: "Nom de l'association", valueGetter: getNameAsso},
            {field: 'address', minWidth: 350, flex: 5, headerName: "Adresse", valueGetter: getAddressAsso},
            {field: '', type: 'actions', minWidth: 200, flex: 2, renderCell: (params) => (
              <Grid container direction="row" justifyContent="space-evenly">
                <Button
                  variant="outlined"
                  startIcon={<Edit />}
                  onClick={() => {
                    history.push(`${path}/edit/${params.id}`);
                  }}
                >Editer</Button>
                <Button
                  variant="outlined"
                  color='error'
                  startIcon={<Delete />}
                  onClick={() => {
                    setDeletedAsso(params.row);
                  }}
                >Supprimer</Button>
              </Grid>
            )}
          ]}
        />
      )}

      {deletedAsso && (
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onBackdropClick={(event) => {
            setOpenDialog(false);
            setDeletedAsso();
          }}
        >
          <DialogTitle>{"Confirmation de suppression"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{`Voulez-vous vraiment supprimer l'association "${deletedAsso.name}" ?`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setDeletedAsso();
                setOpenDialog(false);
              }}
            >Non</Button>
            <Button
              variant="contained"
              onClick={() => {
                deleteAsso(deletedAsso.profile.idProfile)
                .then(result => {
                  setOpenDialog(false);
                  getAsso();
                })
              }}
            >Oui</Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  )
}