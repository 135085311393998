import {createTheme} from '@mui/material/styles';
import { ThemeProvider, Container, Typography, TextField, Grid, Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect, useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { constants } from '../../common/constants';
import {useParams} from 'react-router-dom';
import { AuthContext } from '../../navigation/AuthProvider';
import { styles } from '../../common/style';
import { CircularProgress } from '@material-ui/core';

const theme = createTheme();

/**
 * Formulaire permettant à un utilisateur de définir un nouveau mot de passe
 */
export default function NewPassword() {
  const [malformedUrl, setMalformedUrl] = useState();
  const [success, setSuccess] = useState(false);
  const [isPerson, setIsPerson] = useState(true);
  const [message, setMessage] = useState();

  const {errorAuth, setErrorAuth, changePassword} = useContext(AuthContext);
  let {token} = useParams();
  let history = useHistory();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Veuillez entrer un nouveau mot de passe"),
    password2: Yup.string()
    .oneOf([Yup.ref('password')], 'Les mots de passe doivent correspondre')
  })

  const {handleSubmit, control} = useForm({
    resolver: yupResolver(validationSchema)
  })

  /**
   * Gestion de l'envoi du formulaire une fois rempli
   * @param {*} data Données du formulaire sauvegardées dans la plateforme
   */
  const onSubmit = (data) => {
    const body = {
      newPassword: data.password,
      token: token
    }
    changePassword(body)
    .then(result => {
      console.log(result);
      if(result.statusCode) {
        setErrorAuth(result.message);
        setSuccess(false);
      }
      else {
        setSuccess(true);
        setErrorAuth();
        setIsPerson(result.data.isPerson);
        setMessage(result.message);
      }
    })
  }

  useEffect(() => {
    fetch(constants.url + '/auth/password/verify/' + token)
    .then(response => {
      response.json()
      .then(result => {
        if(!result.statusCode) {
          setMalformedUrl(false);
          setErrorAuth();
        } else {
          setMalformedUrl(true);
          setErrorAuth(result.message);
        }
      })
    })
  }, [])

  /*const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data.get("password"));
  }*/

  return(
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          
          {malformedUrl === undefined ? (
            <CircularProgress />
          ) : (
            <Container>
              {malformedUrl && (
                <Typography color={styles.auth.error.color}>{errorAuth}</Typography>
              )}

              {!malformedUrl && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Typography variant="h5">Nouveau mot de passe</Typography>

                  {success === true ? (
                    <Container>
                      <Typography>{message}</Typography>
                      {isPerson === false && (
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() => history.push('/form')}
                        >Se connecter</Button>
                      )}
                    </Container>
                  ) : (
                    <Container>
                      <Typography>Veuillez sélectionner un nouveau mot de passe pour pouvoir vous reconnecter à la plateforme</Typography>

                      <Grid container maxWidth="lg" direction="column" rowSpacing={2}>

                        <Grid item>
                          <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({field, fieldState: {error}}) => 
                              <TextField
                                {...field}
                                fullWidth
                                label="Votre nouveau mot de passe"
                                error={!!error}
                                helperText={error? error.message: null}
                              />
                            }
                          />
                        </Grid>

                        <Grid item>
                          <Controller
                            name="password2"
                            control={control}
                            defaultValue=""
                            render={({field, fieldState: {error}}) => 
                              <TextField
                                {...field}
                                fullWidth
                                label="Veuillez retaper votre nouveau mot de passe"
                                error={!!error}
                                helperText={error? error.message: null}
                              />
                            }
                          />
                        </Grid>

                        <Grid item>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                          >Mettre à jour mon mot de passe</Button>
                        </Grid>

                      </Grid>
                    </Container>
                  )}
                </form>
              )}
            </Container>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  )
}