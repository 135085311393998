import { Delete, Edit } from "@mui/icons-material";
import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import { DataGrid, frFR } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { TypeCategory } from "../../common/enum";
import { AuthContext } from "../../navigation/AuthProvider";

/**
 * Composant qui affiche une liste de services locaux dans un DataGrid
 * @param match Permet de récupérer l'url exacte où se trouve l'utilisateur en ce moment
 * @returns Un Container dans lequel se trouve un DataGrid. Chaque colonne est un attribut d'un service local et chaque ligne un service
 */
export default function ListServices({match}:any) {
  const {path} = match;
  const history = useHistory();
  const {getAllServices, deleteService} = useContext(AuthContext);

  const [services, setServices] = useState<any>();
  const [deletedService, setDeletedService] = useState<any>(null);
  // Utilisé pour ouvrir ou fermer la fenêtre de confirmation de suppression d'une activité
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  /**
   * Récupération de tous les services locaux présents dans la plateforme
   */
  const getServices = () => {
    getAllServices()
    .then((response:any) => {
      console.log(response);
      setServices(response);
    })
  }

  useEffect(() => {
    getServices()
  }, []);

  // React ouvre le "dialog" de confirmation une fois qu'il sait quelle activité doit être supprimée
  useEffect(() => {
    handleClickOpenDialog();
  }, [deletedService]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  /**
   * Récupération du type de catégorie de services
   * @param params Tous les attributs d'une catégorie
   * @returns Un String qui est fonction du type de catégorie du service correspondant
   */
  const getTypeCategory = (params:any) => {
    return params.row.categories.length > 0 ? (params.row.categories[0].type === TypeCategory.institution ? "Institution" : "Annuaire de professionnels") : ("")
  }

  /**
   * Retourne le nom de la catégorie parent d'un service local
   * @param params Ensemble des données liées à un service local se trouvant à une ligne de la DataGrid
   * @returns Le nom de la catégorie parent du service local
   */
  const getParentCategory = (params:any) => {
    return params.row.categories.length > 0 ? (params.row.categories[0].parent?.name) : ("");
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return(
    <Container maxWidth="xl" style={{flexGrow: 1, display: "flex", width: '100%', flexDirection: "column"}}>
      <Typography variant="h4">Gestion des services locaux</Typography>
      <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">Ajouter un service local</Link>

      {services && (
        <DataGrid
          autoHeight
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(r:any) => r.idLocalService}
          columns={[
            {field: 'name', minWidth: 130, flex: 1, headerName: "Nom"},
            {field: 'parentCategory', minWidth: 100, flex: 1, headerName: "Catégorie de service", valueGetter: getParentCategory},
            {field: 'typeCategory', minWidth: 130, flex: 1, headerName: "Type de service", valueGetter: getTypeCategory},
            {field: ' ', type: 'actions', flex: 1, minWidth: 200, renderCell: (params:any) => (
              <Grid container direction="row" justifyContent="space-evenly">
                <Button
                  variant="outlined"
                  startIcon={<Edit />}
                  onClick={() => {
                    history.push(`${path}/edit/${params.id}`);
                  }}
                >Editer</Button>
                <Button
                  variant="outlined"
                  startIcon={<Delete />}
                  color='error'
                  onClick={() => {
                    //console.log(params.row);
                    setDeletedService(params.row);
                  }}
                >Supprimer</Button>
              </Grid>
            )}
          ]}
          rows={services}
        />
      )}

      {deletedService !== null && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirmation de suppression"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Voulez-vous vraiment supprimer le service "{deletedService.name}" ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => handleCloseDialog()}
            >Non</Button>
            <Button
              variant="contained"
              onClick={() => {
                deleteService(deletedService.idLocalService)
                .then((result:any) => {
                  handleCloseDialog();
                  getServices();
                })
              }}
            >Oui</Button>
          </DialogActions>
        </Dialog>
      )}

    </Container>
  )
}