import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ConfirmEmail from '../screens/auth/ConfirmEmail';
import ForgotPassword from '../screens/auth/ForgotPassword';
import NewPassword from '../screens/auth/NewPassword';
import SignInScreen from '../screens/auth/SignInScreen';
import SignUpScreen from '../screens/auth/SignUpScreen';

/**
 * Navigateur qui gère tout ce qui est en rapport avec l'authentification de l'utilisateur. Il permet à l'utilisateur de se connecter, se créer un compte, confirmer son adresse mail, demander une réinitialisation de son mot de passe et modifier son mot de passe
 * @returns Un Switch qui redirige l'utilisateur en fonction de ce qu'il veut faire en rapport avec son authentification
 */
export default function AuthNavigator() {
  let {url} = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/login`} component={SignInScreen} />
      <Route path={`${url}/signup`} component={SignUpScreen} />
      <Route path={`${url}/email/confirm/:token`} component={ConfirmEmail}/>
      <Route path={`${url}/forgot-password`} component={ForgotPassword}/>
      <Route path={`${url}/new-password/:token`} component={NewPassword}/>
    </Switch>
  )
}