import React, { useContext } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Role } from "../common/enum";
import ActivateAccounts from "../screens/ActivateAccounts";
import Activities from "../screens/activities/Activities";
import Asso from "../screens/associations/Asso";
import FormScreen from "../screens/FormScreen";
import ImportantNumbersScreen from "../screens/important-numbers/ImportantNumbersScreen";
import LocalServicesScreen from "../screens/local-services/LocalServicesScreen";
import { NavBar } from "../screens/NavBar";
import InterestsScreen from "../screens/themes-interests/InterestsScreen";
import ThemesScreen from "../screens/themes-interests/ThemesScreen";
import ProtectedRoute from "../_helpers/ProtectedRoutes";
import { AuthContext } from "./AuthProvider";

/**
 * Navigateur qui gère tout ce qui est en rapport avec l'application, une fois que l'utilisateur est connecté. Il permet à l'utilisateur de se diriger vers les activités, les services, les thématiques, les centres d'intérêts, l'activation de compte, les associations,  et les numéros importants, si l'utilisateur est administrateur
 * @returns Un Switch qui redirige l'utilisateur ainsi que la barre de navigation
 */
export default function HomeNavigator() {
  let {url} = useRouteMatch();
  const {getCurrentUser, hasRole} = useContext(AuthContext);
  const user = getCurrentUser();
  var isAdmin;
  if (user) {
    isAdmin = hasRole(Role.ADMIN);
  }

  return (
    <div>
      <NavBar />
      <Switch>
        <ProtectedRoute exact path={`${url}`} component={FormScreen}/>
        <ProtectedRoute path={`${url}/activities`} component={Activities}/>

        {isAdmin ? (
          <Route path={`${url}/local-service`} component={LocalServicesScreen}/>
        ) : (
          <Redirect to={`${url}`} />
        )}

        {isAdmin ? (
          <Route path={`${url}/themes`} component={ThemesScreen}/>
        ) : (
          <Redirect to={`${url}`} />
        )}

        {isAdmin ? (
          <Route path={`${url}/interests`} component={InterestsScreen}/>
        ) : (
          <Redirect to={`${url}`} />
        )}

        {isAdmin ? (
          <Route path={`${url}/accounts/activate`} component={ActivateAccounts}/>
        ) : (
          <Redirect to={`${url}`} />
        )}

        {isAdmin ? (
          <Route path={`${url}/accounts`} component={Asso}/>
        ) : (
          <Redirect to={`${url}`} />
        )}

        {isAdmin ? (
          <Route path={`${url}/important-numbers`} component={ImportantNumbersScreen}/>
        ) : (
          <Redirect to={`${url}`} />
        )}
      </Switch>
    </div>
  )
}