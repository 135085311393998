import { Button, Container, Typography, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Grid } from "@mui/material";
import { SetStateAction, useContext, useEffect, useState } from "react";
import { DataGrid, frFR } from '@mui/x-data-grid';
import { AuthContext } from "../../navigation/AuthProvider";
import { Link, useHistory } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";

/**
 * Composant qui affiche une liste de thématiques ou de centres d'intérêts en fonction de la page sur laquelle l'utilisateur se trouve
 * @param match Permet de récupérer l'url exacte où se trouve l'utilisateur en ce moment
 * @returns Un Container dans lequel se trouve un DataGrid qui affiche les données sous forme de tableau. En fonction des données affichées, chaque colonne est respectivement un attribut d'un centre d'intérêt ou d'une thématique et chaque ligne est respectivement un centre d'intérêt ou une thématique
 */
export default function ListThemesOrInterests({ match }:any) {
  const {getAllThemes, deleteTheme, getAllTags, deleteTag} = useContext(AuthContext);
  const history = useHistory();
  const { path } = match;

  const [themes, setThemes] = useState<any>();
  const [interests, setInterests] = useState<any>();
  const [whichData, setWhichData] = useState<string>();
  const [deletedThemeOrInterest, setDeletedThemeOrInterest] = useState<any>();
  // Utilisé pour ouvrir ou fermer la fenêtre de confirmation de suppression d'une activité
  const [openDialog, setOpenDialog] = useState(false);

  /**
   * Récupération de toutes les thématiques de la plateforme
   */
  const getThemes = () => {
    getAllThemes()
    .then((response: SetStateAction<undefined>) => {
      console.log(response);
      setThemes(response);
    })
  }

  /**
   * Récupération de tous les centres d'intérêts de la plateforme
   */
  const getInterests = () => {
    getAllTags()
    .then((response: SetStateAction<undefined>) => {
      console.log(response);
      setInterests(response);
    })
  }

  useEffect(() => {
    // Gestion thématiques
    if (path.includes("themes")) {
      getThemes();
      setWhichData("themes");
    }
    // Gestion centres d'intérêt
    else if (path.includes("interests")) {
      getInterests();
      setWhichData("interests");
    }
  }, [])

  // React ouvre le "dialog" de confirmation une fois qu'il sait quelle activité doit être supprimée
  useEffect(() => {
    handleClickOpenDialog();
  }, [deletedThemeOrInterest])

  /**
   * Rendu des boutons de modification ou suppression d'une ligne du tableau
   * @param params La ligne du DataGrid concernée
   */
  const renderActions = (params:any) => (
    <Grid container direction="row" justifyContent="space-evenly">
      <Button
        variant="outlined"
        startIcon={<Edit />}
        onClick={() => {
          history.push(`${path}/edit/${params.id}`);
        }}
      >Editer</Button>
      <Button
        variant="outlined"
        startIcon={<Delete />}
        color='error'
        onClick={() => {
          setDeletedThemeOrInterest(params.row);
        }}
      >Supprimer</Button>
    </Grid>
  )

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  return (
    <Container maxWidth="xl" style={{flexGrow: 1, display: "flex", width: '100%', flexDirection: "column"}}>
      <Typography variant="h4">{whichData === "themes" ? ("Gestion des thématiques"):("Gestion des centres d'intérêt")}</Typography>
      <Link to={`${path}/add`} className="btn btn-sm btn-success mb-2">{whichData === "themes"? ("Ajouter une thématique"):("Ajouter un centre d'intérêt")}</Link>

      {themes && (
        <DataGrid
          autoHeight
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(r) => r.idTheme}
          rows={themes}
          loading={themes === undefined}
          columns={[
            {field: 'name', headerName: 'Nom', minWidth: 150, flex: 1},
            {field: 'description', headerName: "Description de la thématique", minWidth: 250, flex: 4},
            {field: ' ', type: 'actions', minWidth: 150, flex: 1, renderCell: renderActions}
          ]}
        />
      )}

      {interests && (
        <DataGrid
          autoHeight
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          getRowId={(r) => r.idTag}
          rows={interests}
          loading={interests === undefined}
          columns={[
            {field: 'name', headerName: 'Nom', minWidth: 150, flex: 1},
            {field: 'description', headerName: "Description du centre d'intérêt", minWidth: 250, flex: 4},
            {field: ' ', type: 'actions', minWidth: 150, flex: 1, renderCell: renderActions}
          ]}
        />
      )}



      {deletedThemeOrInterest && (
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirmation de suppression"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {whichData === "themes" ? (
                  `Voulez-vous vraiment supprimer la thématique "${deletedThemeOrInterest.name}" ?`
                ) : (
                  `Voulez-vous vraiment supprimer le centre d'intérêt "${deletedThemeOrInterest.name}" ?`
                )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => handleCloseDialog()}
            >Non</Button>
            <Button
              variant="contained"
              onClick={() => {
                if (whichData === "themes") {
                  deleteTheme(deletedThemeOrInterest.idTheme)
                  .then(() => {
                    handleCloseDialog();
                    getThemes();
                  })
                }
                else if (whichData === "interests") {
                  deleteTag(deletedThemeOrInterest.idTag)
                  .then(() => {
                    handleCloseDialog();
                    getInterests();
                  })
                }
              }}
            >Oui</Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  )
}