import React, { createContext, useState } from "react";
import { constants } from "../common/constants";
import {useSnackbar} from 'notistack';
import { TypeMethod } from "../common/enum";

/** Création d'un contexte permettant de faire passer des données à travers l'arborescence d'un composant sans avoir à passer manuellement les proprs à chaque niveau */
export const AuthContext = createContext();

/**
 * Provider du contexte d'authentification. Il fournit tout un tas de méthodes permettant de bien gérer l'authentification de l'utilisateur et tout ce que l'utilisateur peur accomplir sur la plateforme une fois connecté
 * @param {*} children Ensemble des composants fils du provider
 * @returns Un provider d'authentification
 */
export const AuthProvider = ({ children }) => {
  const {enqueueSnackbar} = useSnackbar();
  // Message d'erreur lorsque la requête ne fonctionne pas
  const [errorAuth, setErrorAuth] = useState();

  // True si l'utilisateur est connecté, false sinon
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const baseUrl = constants.url;

  /**
   * Connexion de l'utilisateur sur la plateforme
   * @param {string} username Adresse mail de l'utilisateur
   * @param {string} password Mot de passe de l'utilisateur
   * @returns Un objet contenant les données du profil, l'access token et le refresh token
   */
  const signIn = (username, password) => {
    return fetch(constants.url + "/auth/login", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    })
      .then(response => response.json())
      .then(result => {
        if (result.statusCode) {
          if (result.statusCode !== 401) {
            enqueueSnackbar(result.message, {
              variant: "error"
            });
          }
        }
        else if (result.message) {
          enqueueSnackbar(result.message, {
            variant: "success"
          });
        }
        return result;
      })
      .catch(error => {
        if (error.message === "Network request failed") {
          enqueueSnackbar("Une erreur réseau est survenue. Veuillez vérifier votre connexion à internet et recommencer.", {
            variant: "error"
          });
        } else throw error;
      })
  }

  /**
   * Création d'un compte pour un utilisateur
   * @param {*} body Données récupérées dans le formulaire de création de compte
   * @returns Un message de succès
   */
  const signUp = (body) => {
    console.log(body);

    return fetch(constants.url + "/auth/register/asso", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(result => {
      if (result.statusCode) {
        if (result.statusCode !== 401) {
          enqueueSnackbar(result.message, {
            variant: "error"
          });
        }
      }
      else if (result.message) {
        enqueueSnackbar(result.message, {
          variant: "success"
        });
      }
      return result;
    })
    .catch(error => {
      if (error.message === "Network request failed") {
        enqueueSnackbar("Une erreur réseau est survenue. Veuillez vérifier votre connexion à internet et recommencer.", {
          variant: "error"
        });
      } else throw error;
    })
  }

  /**
   * Déconnexion de l'utilisateur de la plateforme
   */
  const signOut = () => {
    sessionStorage.removeItem("userSession");
  }

  /**
   * Récupération des données de l'utilisateur stockées dans le session storage
   * @returns Un JSON contenant les données de l'utilisateur connecté
   */
  const getCurrentUser = () => {
    return JSON.parse(sessionStorage.getItem("userSession"));
  }

  /**
   * Méthode qui retourne si oui ou non un utilisateur a un certain rôle
   * @param {Role} role : role demandé pour effectuer une action
   * @returns true si l'utilisateur a le rôle demandé, false sinon
   */
  const hasRole = (role) => {
    return getCurrentUser()?.profile.roles.includes(role);
  }

  /**
   * Méthode qui retourne un booléen en fonction de si une adresse mail est déjà utilisée ou non
   * @param {string} email email à vérifier
   * @returns true si l'email est déjà utilisé, false sinon
   */
  const isEmailUsed = async (email) => {
    const endpoint = constants.url + "/profile/isEmailUsed?email=" + email;
    const requestOptions = {
      headers: {
        Accept: 'application/json'
      }
    };
    return fetch(endpoint, requestOptions)
    .then(res => res.json())
    .then(data => {
      return data;
    });
  }

  /**
   * Création du body d'une requête
   * @param {string} method Type de méthode de la requête (GET, POST, PUT, DELETE, ...)
   * @param {object} body Données à mettre dans le body de la requête
   * @returns Un objet contenant les options d'une requête REST
   */
  const createBodyRequest = async (method, body) => {
    const accessToken = sessionStorage.getItem("accessToken");

    let options = {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json'
      }
    };

    if (method !== undefined) {
      options.method = method;
      
      if (method !== TypeMethod.get) {
        options.headers["Content-Type"] = "application/json"
        options.body = JSON.stringify(body);
      }
    }
    return options;
  }






  // -----------
  // ASSOCIATION
  // -----------

  /**
   * Création d'une association par un administrateur
   * @param {object} body Données de création de l'association
   * @returns La réponse de la requête
   */
  const createAssoByAdmin = (body) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(body)
    };
    const url = baseUrl + '/profile/association/';
    return makeRequest(url, requestOptions);
  }

  /**
   * Création d'un compte d'une association déjà créée par un admininistrateur auparavant
   * @param {number} id Id du compte créé par un administrateur
   * @param {object} body Données de création du compte
   * @returns La réponse de la requête
   */
  const registerAssoCreatedByAdmin = (id, body) => {
    const requestOptions = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(body)
    };
    const url = baseUrl + '/auth/register/asso/created-by-admin/' + id;
    return makeRequest(url, requestOptions);
  }

  /**
   * Récupération des détails d'une association
   * @param {number} id Id de l'association
   * @returns Le détail d'une association
   */
  const getAssoDetails = (id) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json'
      },
      method: 'GET'
    };
    const url = baseUrl + '/profile/association/' + id;
    return makeRequest(url, requestOptions);
  }

  /**
   * Modification d'une association
   * @param {number} id Id de l'association à modifier
   * @param {object} body Données de modification de l'association
   * @returns Le résultat de la modification de l'association
   */
  const updateAssociation = (id, body) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    const url = baseUrl + "/profile/association/" + id;

    return makeRequest(url, requestOptions);
  }

  /**
   * Suppression d'une association
   * @param {number} id Id de l'association à supprimer
   * @returns Le résultat de la suppression
   */
  const deleteAsso = (id) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    const url = baseUrl + "/profile/" + id;

    return makeRequest(url, requestOptions);
  }

  /**
   * Récupération de toutes les associations réelles
   * @returns Un tableau d'associations
   */
  const getAllRealAsso = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json'
      },
      method: 'GET'
    };
    const url = baseUrl + '/profile/association/real'
    return makeRequest(url, requestOptions);
  }

  /**
   * Récupération de toutes les associations créées par un administrateur
   * @returns Un tableau d'associations
   */
  const getAllAssoCreatedByAdmin = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json'
      },
      method: 'GET'
    };
    const url = baseUrl + '/profile/association/created-admin'
    return makeRequest(url, requestOptions);
  }

  /**
   * Récupération de toutes les associations réelles créées par un administrateur
   * @returns Un tableau d'associations
   */
  const getAllRealAssoCreatedByAdmin = () => {
    const requestOptions = {
      headers: {
        Accept: 'application/json'
      },
      method: 'GET'
    };
    const url = baseUrl + '/profile/association/created-admin/real';
    return makeRequest(url, requestOptions);
  }


  // --------
  // PERSONNE
  // --------

  /**
   * Récupération des détails d'une personne
   * @param {number} id Id de la personne
   * @returns Objet contenant l'id, la biographie, les hobbies, la localité, les offres, les centres d'intérêt et le médiateur de l'utilisateur
   */
  const getPersonDetails = (id) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json'
      },
      method: 'GET'
    };
    const url = baseUrl + '/profile/person/' + id;
    return makeRequest(url, requestOptions);
  }

  /**
   * Modification d'une personne
   * @param {number} id Id de la personne
   * @param {object} body Données de modification de la personne
   * @returns Le résultat de la mise à jour et un message de succès
   */
  const updatePerson = (id, body) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    const url = baseUrl + "/profile/person/" + id;

    return makeRequest(url, requestOptions);
  }





  // ---------
  // ACTIVITES
  // ---------

  /**
   * Récupération de toutes les activités
   * @returns Un tableau d'activités
   */
  const getAllActivities = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json'
      },
      method: 'GET'
    };
    const url = baseUrl + "/activity/all";
    return makeRequest(url, requestOptions);
  }

  /**
   * Récupération de toutes les activités réelles
   * @returns Un tableau d'activités
   */
  const getAllRealActivities = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json'
      },
      method: 'GET'
    };
    const url = baseUrl + "/activity/all/real";
    return makeRequest(url, requestOptions);
  }

  /**
   * Récupération de toutes les activités organisées par une association
   * @param {number} id Id de l'association
   * @returns un tableau d'activités
   */
  const getActivitiesFromOneOrganizer = (id) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json'
      },
      method: 'GET'
    };
    const url = baseUrl + "/profile/" + id + "/activities";
    return makeRequest(url, requestOptions);
    /*return fetch(url, requestOptions)
    .then(response => {
      return response.json()
    });*/
  }

  /**
   * Récupération des détails d'une activité
   * @param {number} id Id de l'activité
   * @returns L'activité recherchée
   */
  const getActivityDetails = (id) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json'
      },
      method: 'GET'
    }
    const url = baseUrl + "/activity/" + id;
    return makeRequest(url, requestOptions);
    /*return fetch(url, requestOptions)
    .then(response => {
      return response.json()
    })*/
  }

  /**
   * Création d'une activité organisée par une association
   * @param {number} id Id de l'association organisatrice
   * @param {object} body Données de création de l'activité
   * @returns 
   */
  const createActivity = (id, body) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    const url = baseUrl + "/profile/" + id + "/activity"

    return makeRequest(url, requestOptions);
    /*return fetch(url, requestOptions)
    .then(handleResponse)*/
  }

  /**
   * Modification d'une activité organisée par une association
   * @param {number} idActi Id de l'activité
   * @param {number} idProfile Id de l'association
   * @param {object} body Données de modification de l'activité
   * @returns 
   */
  const updateActivity = (idActi, idProfile, body) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    const url = baseUrl + "/profile/" + idProfile + "/activity/" + idActi;

    return makeRequest(url, requestOptions);
    /*return fetch(url, requestOptions)
    .then(handleResponse)*/
  }

  /**
   * Méthode permettant de supprimer une activité de la base de données
   * @param {number} idActi : id de l'activité
   * @param {number} idProfile : id de l'association qui organise l'activité
   * @returns La réponse de la requête
   */
  const deleteActivity = (idActi, idProfile) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    const url = baseUrl + '/profile/' + idProfile + '/activity/' + idActi;
    return makeRequest(url, requestOptions);
  }



  // ---------------
  // SERVICES LOCAUX
  // ---------------

  /**
   * Création d'un service local
   * @param {object} body Données de création du service
   * @returns Le service nouvellement créé et un message de succès
   */
  const createService = async (body) => {
    const endpoint = baseUrl + '/service';
    const requestOptions = await createBodyRequest(TypeMethod.post, body);
    return makeRequest(endpoint, requestOptions);
  }

  /**
   * Modification d'un service local
   * @param {number} idService Id du service local
   * @param {object} body Données de modification du service
   * @returns Le résultat de la modification et un message de succès
   */
  const updateService = async (idService, body) => {
    const endpoint = baseUrl + '/service/' + idService;
    const requestOptions = await createBodyRequest(TypeMethod.put, body);
    return makeRequest(endpoint, requestOptions);
  }

  /**
   * Récupération de tous les services locaux de la plateforme
   * @returns Tableau de services 
   */
  const getAllServices = async () => {
    const endpoint = baseUrl + '/services';
    const requestOptions = await createBodyRequest(TypeMethod.get);
    return makeRequest(endpoint, requestOptions);
  }

  /**
   * Récupération d'un service local
   * @param {number} id Id du service
   * @returns Le service local recherché
   */
  const getOneService = async (id) => {
    const endpoint = baseUrl + "/service/" + id;
    const requestOptions = await createBodyRequest(TypeMethod.get);
    return makeRequest(endpoint, requestOptions);
  }

  /**
   * Suppression d'un service local
   * @param {number} id Id du service à supprimer
   * @returns Le résultat de la suppression et un message de succès
   */
  const deleteService = async (id) => {
    const endpoint = baseUrl + '/service/' + id;
    const requestOptions = await createBodyRequest(TypeMethod.delete);
    return makeRequest(endpoint, requestOptions);
  }


  // ----------------------
  // CATEGORIES DE SERVICES
  // ----------------------

  /**
   * Récupération de toutes les catégories de service
   * @returns Tableau de catégories
   */
  const getCategories = async () => {
    const endpoint = baseUrl + '/categories';
    const requestOptions = await createBodyRequest(TypeMethod.get);
    return makeRequest(endpoint, requestOptions);
  }



  // ------------------
  // NUMEROS IMPORTANTS
  // ------------------

  /**
   * Récuoération d'un numéro important
   * @param {number} id Id du numéro
   * @returns Le numéro recherché
   */
  const getOneImportantNumber = async (id) => {
    const endpoint = baseUrl + '/important-number/' + id;
    const requestOptions = await createBodyRequest(TypeMethod.get);
    return makeRequest(endpoint, requestOptions);
  }

  /**
   * Récupération de tous les numéros importants de la plateforme
   * @returns Un tableau de numéros importants
   */
  const getAllImportantNumbers = async () => {
    const endpoint = baseUrl + '/important-numbers';
    const requestOptions = await createBodyRequest(TypeMethod.get);
    return makeRequest(endpoint, requestOptions);
  }

  /**
   * Suppression d'un numéro important
   * @param {number} id Id du numéro important à supprimer
   * @returns Un message de succès ou d'erreur en fonction du résultat de la suppression
   */
  const deleteImportantNumber = async (id) => {
    const endpoint = baseUrl + '/important-number/' + id;
    const requestOptions = await createBodyRequest(TypeMethod.delete);
    return makeRequest(endpoint, requestOptions);
  }

  /**
   * Création d'un numéro important
   * @param {number} body Données de création du numéro important
   * @returns Le numéro nouvellement créé
   */
  const createImportantNumber = async (body) => {
    const endpoint = baseUrl + '/important-number';
    const requestOptions = await createBodyRequest(TypeMethod.post, body);
    return makeRequest(endpoint, requestOptions);
  }

  /**
   * Modification d'un numéro important
   * @param {number} idNumber Id du numéro
   * @param {object} body Données de modification du numéro important
   * @returns Le numéro modifié
   */
  const updateImportantNumber = async (idNumber, body) => {
    const endpoint = baseUrl + '/important-number/' + idNumber;
    const requestOptions = await createBodyRequest(TypeMethod.put, body);
    return makeRequest(endpoint, requestOptions);
  }



  // ------------------
  // CENTRES D'INTERETS
  // ------------------

  /**
   * Récupération de tous les Tags/centres d'intérêt
   * @returns Un tableau de tous les Tags
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const getAllTags = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    const url = baseUrl + '/tag';
    return makeRequest(url, requestOptions);
  }

  /**
   * Récupération d'un Tag/centre d'intérêt à partir de son id
   * @param {number} id Id du Tag
   * @returns Un Tag
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const getOneTag = (id) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    const url = baseUrl + '/tag/' + id;
    return makeRequest(url, requestOptions);
  }

  /**
   * Création d'un Tag/centre d'intérêt
   * @param {object} body Données de création du Tag ainsi qu'une possible icone liée au Tag
   * @returns Le Tag créé et un message de succès
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const createTag = (body) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      body: body
    }
    const url = baseUrl + "/tag";
    return makeRequest(url, requestOptions);
  }

  /**
   * Modification d'un Tag/centre d'intérêt à partir de son id et des données à modifier
   * @param {number} idTag Id du Tag
   * @param {object} body Données à modifier dans le Tag
   * @returns Le Tag modifié et un message de succès
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const updateTag = (idTag, body) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + accessToken
      },
      body: body
    }
    const url = baseUrl + '/tag/' + idTag;
    return makeRequest(url, requestOptions);
  }

  /**
   * Suppression d'un Tag/centre d'intérêt à ,artir de son id
   * @param {number} idTag Id du Tag
   * @returns Le Tag supprimé et un message de succès
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const deleteTag = (idTag) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    const url = baseUrl + "/tag/" +idTag;
    return makeRequest(url, requestOptions);
  }
  



  // -----------
  // THEMATIQUES
  // -----------

  /**
   * Récupération de toutes les thématiques de la plateforme
   * @returns Un tableau de Theme
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const getAllThemes = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    const url = baseUrl + '/theme';
    return makeRequest(url, requestOptions);
  }

  /**
   * Récupération d'une thématique grâce à son id
   * @param {number} id Id de la thématique
   * @returns Un Theme
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const getOneTheme = (id) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    const url = baseUrl + '/theme/' + id;
    return makeRequest(url, requestOptions);
  }

  /**
   * Création d'une thématique à partir de données
   * @param {object} body Données de création de la thématique
   * @returns La thématique créée et un message de succès
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const createTheme = (body) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: "Bearer " + accessToken
      },
      body: body
    }
    const url = baseUrl + "/theme";
    return makeRequest(url, requestOptions);
  }

  /**
   * Suppression d'une thématique
   * @param {number} idTheme Id de la thématique
   * @returns Le Theme supprimé et un message de succès
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const deleteTheme = (idTheme) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    const url = baseUrl + '/theme/' + idTheme;
    return makeRequest(url, requestOptions);
  }

  /**
   * Modification d'une thématique
   * @param {number} idTheme Id de la thématique
   * @param {object} body Données à modifier
   * @returns 
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const updateTheme = (idTheme, body) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + accessToken
      },
      body: body
    }
    const url = baseUrl + '/theme/' + idTheme;
    return makeRequest(url, requestOptions);
  }




  // ------
  // COMPTE
  // ------

  /**
   * Récupération des comptes d'association nouvellement
   * créés et pas encore validés par un admin
   * @returns L'ensemble des comptes pas encore validés
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const getPendingAccounts = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    const url = baseUrl + '/profile/association/pending'
    return makeRequest(url, requestOptions);
  }

  /**
   * Récupération de tous les comptes d'association réels nouvellement créés et pas encore validés par un administrateur
   * @returns L'ensemble des comptes réels par encore validés
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const getRealPendingAccounts = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
    const url = baseUrl + '/profile/association/pending/real'
    return makeRequest(url, requestOptions);
  }

  /**
   * Activation ou non d'un compte associatif par un admin
   * @param {number} idAsso Id de l'association à accepter ou non
   * @param {object} body adminActivation param is "accepted" or "refused"
   * @returns Le résultat de la requête
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const accountActivation = (idAsso, body) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    const url = baseUrl + '/profile/association/' + idAsso + "/activate"
    return makeRequest(url, requestOptions);
  }





  /**
   * Envoi d'un mail de réinitialisation de mot de passe
   * @param {string} email Adresse mail de l'utilisateur qui demande un nouveau mot de passe
   * @returns Un message de succès
   */
  const forgotPassword = (email) => {
    const url = baseUrl + '/auth/forgot-password/' + email;
    return makeRequest(url);
  }

  /**
   * Modification du mot de passe d'un utilisateur
   * @param {object} body Données contenant le nouveau mot de passe et le token de modification du mot de passe
   * @returns Un message de succès et le type d'utilisateur concerné (personne ou association)
   * @todo Refactoring pour utiliser createBodyRequest à la place de créer les options de la requête manuellement
   */
  const changePassword = (body) => {
    const accessToken = sessionStorage.getItem("accessToken");
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    const url = baseUrl + '/auth/reset-password';
    return makeRequest(url, requestOptions, false);
  }





  /**
   * Méthode permettant de faire une requête à l'API du backend en
   * gérant l'access et le refresh token
   * @param {string} url Url de la requête
   * @param {object} requestOptions Options utilisées pour la requête
   * @param {boolean} showSnackbar True si la réponse de la requête doit être affichée dans un snackbar, false sinon
   * @returns La réponse de la requête
   */
  const makeRequest = (url, requestOptions, showSnackbar = true) => {
    setErrorAuth();
    return fetch(url, requestOptions)
    .then(response => response.json())
    .then(result => {
      //console.log(result);
      if (result.statusCode) {
        switch(result.statusCode){
          case 401:
            return refresh()
            .then((result) => {
              // MAJ de l'access et refresh token dans sessionStorage
              sessionStorage.setItem("accessToken", result.accessToken);
              sessionStorage.setItem("refreshToken", result.refreshToken);

              // MAJ access token dans le header de la requête
              requestOptions["headers"]["Authorization"] = "Bearer " + result.accessToken;
              return makeRequest(url, requestOptions);
            })
          case 500:
            enqueueSnackbar("Une erreur est survenue. Veuillez réessayer votre opération plus tard.", {
              variant: "error"
            });
            break;
          default:
            setErrorAuth(result.message);
            enqueueSnackbar(result.message, {
              variant: "error"
            });
            break;
        }
      }
      else if (result.message && showSnackbar === true) {
        enqueueSnackbar(result.message, {
          variant: "success"
        });
      }
      // La requête retourne le bon résultat
      return result;
    })
    .catch(error => {
      if (error.message === "Network request failed") {
        enqueueSnackbar("Une erreur réseau est survenue. Veuillez vérifier votre connexion à internet et recommencer.", {
          variant: "error"
        });
      }
    })
  }

  /**
   * Méthode permettant de mettre jour l'access token via le refresh token
   * @returns le profil, le nouvel access token et le nouvau refresh token 
   */
  const refresh = () => {
    const refreshToken = sessionStorage.getItem("refreshToken");
    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        "refreshToken": refreshToken
      })
    }
    return fetch(constants.url + "/auth/refresh", options)
      .then(response => {
        return response.json();
      })
  }
  
  return (
    <AuthContext.Provider
      value={{
        errorAuth,
        setErrorAuth,
        signIn,
        signUp,
        signOut,

        getCurrentUser,
        hasRole,
        isEmailUsed,
        userLoggedIn,
        setUserLoggedIn,

        createAssoByAdmin,
        registerAssoCreatedByAdmin,
        getAssoDetails,
        getAllRealAsso,
        updateAssociation,
        deleteAsso,
        getAllAssoCreatedByAdmin,
        getAllRealAssoCreatedByAdmin,

        getPersonDetails,
        updatePerson,

        getAllActivities,
        getAllRealActivities,
        getActivitiesFromOneOrganizer,
        getActivityDetails,
        createActivity,
        updateActivity,
        deleteActivity,

        createService,
        updateService,
        getAllServices,
        getOneService,
        deleteService,

        getCategories,

        getOneImportantNumber,
        getAllImportantNumbers,
        deleteImportantNumber,
        createImportantNumber,
        updateImportantNumber,

        getAllTags,
        getOneTag,
        createTag,
        deleteTag,
        updateTag,

        getAllThemes,
        getOneTheme,
        createTheme,
        deleteTheme,
        updateTheme,

        getPendingAccounts,
        getRealPendingAccounts,
        accountActivation,
        forgotPassword,
        changePassword,
        makeRequest
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}