import { Tab, Tabs } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Role } from '../common/enum';
import { AuthContext } from '../navigation/AuthProvider';

/**
 * Composant s'occupant de la barre de navigation présente sur tous les écrans et permettant de voyager d'écran à écran
 * @returns Une barre de navigation sous forme de Tabs
 */
function NavBar() {
  const {hasRole} = useContext(AuthContext);
  const isAdmin = hasRole(Role.ADMIN);
  const routeMatch = useRouteMatch(['/form', '/form/activities', '/form/themes', '/form/interests', '/form/accounts/activate', '/form/accounts']);
  const [currentTab, setCurrentTab] = useState<any>(routeMatch?.path);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }

  return (
    <Tabs value={currentTab} onChange={handleChange} style={{paddingBottom: 20, paddingTop: 10, paddingLeft: 10}}>
      <Tab style={{minWidth: 110}} label="Accueil" value="/form" to="/form" component={Link}/>
      <Tab style={{minWidth: 110}} label="Activités" value="/form/activities" to="/form/activities" component={Link}/>
      {isAdmin && (
        <Tab style={{minWidth: 150}} label="Services locaux" value="/form/local-service" to="/form/local-service" component={Link}/>
      )}
      {isAdmin && (
        <Tab style={{minWidth: 150}} label="Thématiques" value="/form/themes" to="/form/themes" component={Link}/>
      )}
      {isAdmin && (
        <Tab style={{minWidth: 200}} label="Centres d'intérêt" value="/form/interests" to="/form/interests" component={Link}/>
      )}
      {isAdmin && (
        <Tab style={{minWidth: 200}} label="Numéros importants" value="/form/important-numbers" to="/form/important-numbers" component={Link} />
      )}
      {isAdmin && (
        <Tab style={{minWidth: 200}} label="Activation comptes" value="/form/accounts/activate" to="/form/accounts/activate" component={Link}/>
      )}
      {isAdmin && (
        <Tab style={{minWidth: 200}} label="Création association" value="/form/accounts" to="/form/accounts" component={Link}/>
      )}
    </Tabs>
  );
}

export { NavBar }; 