import React, { useEffect, useState } from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {Container, Typography, Button} from "@mui/material";
import { constants } from '../../common/constants';

/**
 * Fonction qui permet de confirmer (ou non) la validation de l'adresse mail d'un utilisateur
 * @returns Un petit texte qui confirme (ou non) la validation de l'adresse
 */
export default function ConfirmEmail() {
  let params:any = useParams();
  const history = useHistory();

  const [message, setMessage] = useState<string>();
  const [isPerson, setIsPerson] = useState<boolean>(true);
  const token = params.token;

  useEffect(() => {
    const url = constants.url + "/auth/email/verify/" + token;
    fetch(url)
    .then(response => response.json())
    .then(result => {
      console.log(result);
      setMessage(result.message);
      if (!result.statusCode) setIsPerson(result.data.isPerson);
    })
  }, [token])

  return(
    <Container maxWidth="lg">
      <Typography variant="h4">Activation de votre compte</Typography>
      <Typography>{message}</Typography>
      {isPerson === false && (
        <Button
          variant="contained"
          onClick={() => {
            history.push('/form')
          }}
        >Revenir à l'écran de connexion</Button>
      )}
    </Container>
  );
}