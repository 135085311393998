import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Button, CircularProgress, Container, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, ListSubheader, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { Country, TypeCategory } from "../../common/enum";
import { AuthContext } from "../../navigation/AuthProvider";
import mapboxSdk from '@mapbox/mapbox-sdk/services/geocoding';
import { constants } from "../../common/constants";

/**
 * Composant qui permet d'ajouter ou modifier un service local dans la plateforme. L'interface s'adapte en fonction de si c'est un ajout ou une modification mais globalement, c'est le même formulaire dans les deux cas
 * @param history Permet de gérer les routes empruntées par l'utilisateur en fonction de l'action qu'il effectue
 * @param match Permet de récupérer l'url exacte où se trouve l'utilisateur pour l'instant
 * @returns Un formulaire d'ajout ou de modification d'un service local
 */
export default function AddEditLocalService({history, match}:any) {
  const {getOneService, getCategories, createService, updateService} = useContext(AuthContext);
  const {id} = match.params;
  const isAddMode = !id;

  const [service, setService] = useState<any>();
  const [categories, setCategories] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState<any>('');
  const [selectedTypeCategory, setSelectedTypeCategory] = useState(TypeCategory.institution);
  // Pays dans lequel le service se trouve et sélectionné par l'utilisateur
  const [selectedCountry, setSelectedCountry] = useState<any>(Country.belgium);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const [addressChoices, setAddressChoices] = useState<any>([]);
  const [inputValue, setInputValue] = useState<any>("");
  // True si l'utilisateur a modifié la valeur du champ de l'adresse, false sinon
  const [changeInputValue, setChangeInputValue] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);

  var mapboxClient = mapboxSdk({accessToken: constants.mapboxToken});

  useEffect(() => {
    getCategories()
    .then((result:any) => {
      console.log(result);
      setCategories(result);
      return result;
    })
    .then((res:any) => {
      if (!isAddMode) {
        getOneService(id)
        .then((result: any) => {
          console.log(result);
          setService(result);
          setInputValue(result.address);
          if (result.categories.length > 0) {
            setSelectedCategory(JSON.stringify(result.categories[0]));
            setSelectedTypeCategory(result.categories[0].type);
          }
          setSelectedCountry(result.country);
        })
      } else {
        setSelectedCategory(JSON.stringify({"idCategory": res[0].children[0].idCategory, "name": res[0].children[0].name, "type": res[0].children[0].type}));
      }
    })
  }, []);

  const phoneRegex = /(^$|^[0]{2}[3]{1}[2-3]{1}[\.\/ ]{1}[0-9]{3}([\.\/ ]{1}[0-9]{2}){3}$)/;
  
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Veuillez donner un nom au service'),
    description: Yup.string()
      .required("Veuillez donner une description au service"),
    website: Yup.string()
      .required("Veuillez donner le site web du service"),
    phoneContact: Yup.string()
      .matches(phoneRegex, "Veuillez respecter le format demandé"),
    emailContact: Yup.string()
      .email("Veuillez indiquer une adresse mail valide")
  });
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(validationSchema)
  });


  /**
   * Gestion de la sélection d'une catégorie de service par l'utilisateur
   * @param event Evénement déclenché lorsque l'utilisateur sélectionne une catégorie
   */
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value);
    let json = JSON.parse(event.target.value);
    setSelectedTypeCategory(json.type);
  }

  /**
   * Gestion de l'envoi du formulaire une fois rempli
   * @param data Données du formulaire sauvegardées dans la plateforme
   */
  const onSubmit = (data:any) => {
    let jsonCategory = JSON.parse(selectedCategory);
    data.categories = [jsonCategory.idCategory];
    data.country = selectedCountry;

    // Gestion de l'adresse
    if (selectedAddress !== null && typeof data.address === "object") {
      data.latitude = data.address.geometry.coordinates[1];
      data.longitude = data.address.geometry.coordinates[0];
      data.address = data.address["place_name_fr"];
    } else {
      delete data.address;
    }

    // Gestion du type de catégorie
    // Si c'est un annuaire on supprime les données inutiles
    if (selectedTypeCategory === TypeCategory.annualDirectory) {
      data.emailContact = "";
      data.phoneContact = "";
      data.address = "";
      data.latitude = "";
      data.longitude = "";
    }

    console.log(data);

    isAddMode ? createOneService(data) : updateOneService(id, data);
  }

  /**
   * Création d'un service local
   * @param data Données de création d'un service
   */
  const createOneService = async (data:object) => {
    const result = await createService(data);
    if (!result.statusCode) history.push('/form/local-service');
  }

  /**
   * Modification d'un service local
   * @param idService Id du service à modifier
   * @param data Données de modification du service
   */
  const updateOneService = async (idService: number, data: object) => {
    const result = await updateService(idService, data);
    if (!result.statusCode) history.push('/form/local-service');
  }

  return (
    <Container maxWidth="lg">
      {service === undefined && !isAddMode ? (
        <CircularProgress />
      ):(
        <Grid>

          <form onSubmit={handleSubmit(onSubmit)}>

            <Grid container direction="column" rowSpacing={2}>

              <Grid item>
                <Typography variant="h4">{isAddMode ? "Ajout d'un service local" : "Modification d'un service local"}</Typography>
              </Grid>

              {/* Catégorie */}
              <Grid item>
                <FormControl sx={{width: '100%'}}>
                  <InputLabel>Catégorie</InputLabel>
                  <Select
                    id="category-select"
                    label="Catégorie"
                    defaultValue=""
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                  >
                    {// TODO: arriver à afficher ListSubHeader dans le map
                    categories?.map((cat:any, index:number) => ([
                      <ListSubheader>{cat.name}</ListSubheader>,
                      cat.children.map((subCat:any, i:number) => (
                        <MenuItem value={JSON.stringify({"idCategory": subCat.idCategory, "name": subCat.name, "type": subCat.type})} id={subCat.idCategory}>{subCat.name}</MenuItem>
                      ))
                    ]))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Nom du service local */}
              <Grid item>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={isAddMode? "" : service?.name}
                  render={({field, fieldState: {error}}) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Nom du service"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              {/* Description */}
              <Grid item>
                <Controller
                  name="description"
                  control={control}
                  defaultValue={isAddMode? "" : service?.description}
                  render={({field, fieldState: {error}}) => (
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      minRows={2}
                      maxRows={8}
                      variant="outlined"
                      label="Description du service"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              {/* Site web */}
              <Grid item>
                <Controller
                  name="website"
                  control={control}
                  defaultValue={isAddMode? "" : service?.website}
                  render={({field, fieldState: {error}}) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Site web du service"
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              {/* Pays */}
              <Grid item>
                <FormControl>
                  <FormLabel>Pays</FormLabel>
                  <RadioGroup
                    value={selectedCountry}
                    onChange={(e:any) => {
                      setSelectedCountry(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={Country.belgium}
                      label={"Belgique"}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={Country.france}
                      label={"France"}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {selectedTypeCategory === TypeCategory.institution && (
                <Grid item container spacing={2} direction="column">

                  {/* Adresse/code postal */}
                  <Grid item>
                    <Controller
                      name="address"
                      control={control}
                      defaultValue={isAddMode? "" : service?.address}
                      render={({field: {onChange, value}, fieldState: {error}}) => (
                        <Autocomplete
                          value={value}
                          onChange={(e, newValue) => {
                            // On enregistre le fait que l'utilisateur a cliqué sur une adresse ou a supprimé l'adresse sélectionnée
                            setSelectedAddress(newValue);
                            onChange(newValue);
                          }}
                          filterOptions={(x) => x}
                          inputValue={inputValue}
                          onInputChange={(event, newInput) => {
                            setChangeInputValue(true);
                            setInputValue(newInput);
                            if (newInput.length > 2){
                              mapboxClient.forwardGeocode({
                                query: newInput,
                                countries: ['fr', 'be'],
                                language: ['fr'],
                                autocomplete: true,
                                types: ["address", "postcode", "place", "locality"]
                              })
                              .send()
                              .then((response:any) => {
                                setAddressChoices(response.body.features)
                              })
                            }
                          }}
                          freeSolo
                          options={addressChoices}
                          getOptionLabel={(option:any) => option["place_name_fr"] || ""}
                          isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              label="Adresse / code postal de l'association (optionnel)"
                              error={selectedAddress === null && buttonClicked === true && inputValue.length > 0 && changeInputValue}
                              helperText={selectedAddress === null && buttonClicked === true && inputValue.length > 0 && changeInputValue ? "Veuillez indiquer l'adresse ou le code postal de l'association puis le ou la sélectionner dans la liste proposée": null}
                            />
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item container spacing={2}>

                  <Grid item xs={12}>
                    <Typography>Pour le numéro de téléphone, veuillez respecter le format suivant: 0032 456 12 34 56 (avec 0032 pour la Belgique et 0033 pour la France). Vous pouvez remplacer chaque espace par un point ou une barre oblique (ou slash), ce qui peut donner, par exemple, le numéro 0032/456.12.34.56</Typography>
                  </Grid>

                    {/* Numéro de téléphone de contact */}
                    <Grid item sm={6} xs={12}>
                      <Controller
                        name="phoneContact"
                        control={control}
                        defaultValue={isAddMode? "" : service?.phoneContact}
                        render={({field, fieldState: {error}}) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Numéro de téléphone de contact (optionnel)"
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </Grid>

                    {/* Email de contact */}
                    <Grid item sm={6} xs={12}>
                      <Controller
                        name="emailContact"
                        control={control}
                        defaultValue={isAddMode? "" : service?.emailContact}
                        render={({field, fieldState: {error}}) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Adresse mail de contact (optionnel)"
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </Grid>

                  </Grid>

                </Grid>
              )}

              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => setButtonClicked(true)}
                >{isAddMode?("Ajouter un service"):("Terminer les modifications")}</Button>
              </Grid>

            </Grid>

          </form>

        </Grid>

      )}
    </Container>
  )
}