import React from "react";
import { Redirect, Route } from "react-router-dom";
import { PATHS } from "../common/enum";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const user = sessionStorage.getItem("userSession");

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to={'/auth/login'} />
      }
    />
  );
}

export default ProtectedRoute;