import React, {useContext, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, FormHelperText} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AuthContext } from '../../navigation/AuthProvider';
import {styles} from '../../common/style';
import { Controller, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from "react-router";

const theme = createTheme();

/**
 * Fonction qui gère la connexion d'un utilisateur sur la plateforme
 * @returns Une interface de connexion permettant à l'utilisateur de se connecter avec son adresse mail et son mot de passe
 */
export default function SignInScreen() {
  const {signIn, errorAuth, setErrorAuth, setUserLoggedIn} = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  let history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Votre adresse mail doit être valide')
      .required('Veuillez indiquer votre adresse mail'),
    password: Yup.string()
      .required('Veuillez indiquer votre mot de passe')
  })

  const {handleSubmit, control} = useForm({
    resolver: yupResolver(validationSchema)
  });

  /**
   * Gestion de l'envoi du formulaire une fois rempli
   * @param {*} data Données du formulaire sauvegardées dans la plateforme
   */
  const onSubmit = (data) => {
    //console.log(data);
    signIn(data.email, data.password)
    .then(result => {
      if (!result.statusCode) {
        sessionStorage.setItem("userSession", JSON.stringify(result.data));
        sessionStorage.setItem("accessToken", result.data.accessToken);
        sessionStorage.setItem("refreshToken", result.data.refreshToken);
        setUserLoggedIn(true);
        setErrorAuth(null);
      } else {
        console.log(result);
        setErrorAuth(result.message)
      }
    })
    .catch(() => {
      
    })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  }

  /*const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });

    signIn(data.get('email'), data.get('password'));
  };*/

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Connexion
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" rowSpacing={2}>
              <Grid item>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({field, fieldState: {error}}) => 
                    <TextField
                      {...field}
                      fullWidth
                      autoFocus
                      variant="outlined"
                      label="Adresse mail"
                      error={!!error}
                      helperText={error? error.message:null}
                    />
                  }
                />
              </Grid>

              <Grid item>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({field, fieldState: {error}}) => 
                    /*<TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label="Mot de passe"
                      error={!!error}
                      helperText={error? error.message:null}
                    />*/

                    <FormControl
                      variant="outlined"
                      error={!!error}
                    >
                      <InputLabel>Mot de passe</InputLabel>
                      <OutlinedInput
                        {...field}
                        type={showPassword ? 'text' : 'password'}
                        label="Mot de passe"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText>{error ? error.message : null}</FormHelperText>
                    </FormControl>
                  }
                />
              </Grid>
              {/*<TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adresse mail"
                name="email"
                autoComplete="email"
                autoFocus
              />*/}
              {/*<FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Se souvenir de moi"
              />*/}
              {errorAuth && (
                <Typography color={styles.auth.error.color}>{errorAuth}</Typography>
              )}
              <Grid item>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Se connecter
                </Button>
              </Grid>
              <Grid container rowSpacing={1}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    '& > :not(style) + :not(style)': {
                      ml: 2,
                    },
                  }}
                >
                  <Link href="/auth/forgot-password" variant="body2">
                    J'ai oublié mon mot de passe
                  </Link>

                  <Link href="/auth/signup" variant="body2">
                    Je n'ai pas de compte
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  );
}