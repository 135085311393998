import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Button, Typography } from '@mui/material';
//import * as Yup from 'yup';
//import { useForm } from "react-hook-form";
import { AuthContext } from '../navigation/AuthProvider';
//import { yupResolver } from '@hookform/resolvers/yup';
//import mapboxSdk from '@mapbox/mapbox-sdk/services/geocoding';
import {useSnackbar} from 'notistack';
import { PATHS } from '../common/enum';
//import { constants } from '../common/constants';

export default function HomeScreen() {
  const {signOut, setUserLoggedIn} = useContext(AuthContext);
  const {enqueueSnackbar} = useSnackbar();
  let history = useHistory();
  /*const user = getCurrentUser();
  //const id = user.profile.idProfile;

  const [asso, setAsso] = useState();
  const [person, setPerson] = useState();
  //const [coordinates, setCoordinates] = useState();
  const [inputValue, setInputValue] = useState("");
  //const [addressChoices, setAddressChoices] = useState([]);

  //var mapboxClient = mapboxSdk({accessToken: constants.mapboxToken});

  useEffect(() => {
    if (user.profile.asso) {
      getAssoDetails(user.profile.idProfile)
      .then(result => {
        console.log(result);
        setInputValue(result.address);
        setAsso(result);
      })
    } else {
      getPersonDetails(user.profile.idProfile)
      .then(result => {
        setPerson(result);
      })
    }
  }, [])


  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Veuillez donner un nom à votre association')
  })

  const {handleSubmit, control} = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data) => {
    if (typeof data.address === 'object') {
      data["latitude"] = data.address.geometry.coordinates[1];
      data["longitude"] = data.address.geometry.coordinates[0];
      data.address = data.address["place_name_fr"];
    }
    console.log(data);
    return user.profile.asso
      ? updateOneAsso(id, data)
      : updateOnePerson(id, data)

  }

  const updateOneAsso = (id, data) => {
    return updateAsso(id, data)
  }

  const updateOnePerson = (id, data) => {
    console.log("update person");
  }*/

  return (
    <Container maxWidth="xl">
      <Typography variant="h4">Accueil du formulaire</Typography>
      <Button
        variant="contained"
        onClick={() => {
          signOut();
          setUserLoggedIn(false);
          enqueueSnackbar('Vous êtes maintenant déconnecté !', {
            variant: "success"
          });
          
        }}
      >Se déconnecter</Button>

      {/*asso || person ? (
        <Container>
          <Typography variant="h5">Mon profil</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              {
              //L'utilisateur est une association
              }
              {asso && !person && (
                <Grid container direction="column" rowSpacing={2}>

                  {
                  // Nom de l'association
                  }
                  <Grid item>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue={asso.name}
                      render={({field, fieldState: {error}}) => 
                        <TextField
                          {...field}
                          fullWidth
                          variant="outlined"
                          label="Nom de l'association"
                          error={!!error}
                          helperText={error? error.message: null}
                        />
                      }
                    />
                  </Grid>

                  {
                    // Résumé de l'association 
                  }
                  <Grid item>
                    <Controller
                      name="summary"
                      control={control}
                      defaultValue={asso.summary}
                      render={({field, fieldState: {error}}) => 
                        <TextField
                          {...field}
                          fullWidth
                          multiline
                          variant="outlined"
                          label="Brève description de l'association (15 mots)"
                          error={!!error}
                          helperText={error? error.message: null}
                        />
                      }
                    />
                  </Grid>

                  {
                    // Description de l'association
                  }
                  <Grid item>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue={asso.description}
                      render={({field, fieldState: {error}}) => 
                        <TextField
                          {...field}
                          fullWidth
                          multiline
                          variant="outlined"
                          label="Description plus précise de l'association (50 mots)"
                          error={!!error}
                          helperText={error? error.message: null}
                        />
                      }
                    />
                  </Grid>

                  {
                    //Adresse de l'activité
                  }
                  <Grid item>
                    <Controller
                      name="address"
                      control={control}
                      defaultValue={asso.address}
                      render={({field: {onChange, value}, fieldState: {error}}) => 
                        <Autocomplete
                          value={value}
                          onChange={(e, newValue) => {
                            console.log(newValue);
                            onChange(newValue);
                            if (newValue !== null && newValue.length > 0) setCoordinates(newValue.geometry.coordinates);
                          }}
                          filterOptions={(x) => x}
                          inputValue={inputValue}
                          onInputChange={(event, newInput) => {
                            setInputValue(newInput);
                            if (newInput.length > 2){
                              mapboxClient.forwardGeocode({
                                query: newInput,
                                countries: ['fr', 'be'],
                                language: ['fr'],
                                autocomplete: true,
                                types: ["address"]
                              })
                              .send()
                              .then(response => {
                                setAddressChoices(response.body.features)
                              })
                            }
                          }}
                          freeSolo
                          options={addressChoices}
                          getOptionLabel={(option) => option["place_name_fr"] || ""}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          renderInput={(params) => <TextField {...params} label="Adresse de l'association"/>}
                        />
                      }
                    />
                  </Grid>

                  {
                    // Numéro de téléphone de l'association
                  }
                  <Grid item>
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue={asso.phone}
                      render={({field, fieldState: {error}}) => 
                        <TextField
                          {...field}
                          fullWidth
                          variant="outlined"
                          label="Numéro de téléphone de l'association"
                          error={!!error}
                          helperText={error? error.message: null}
                        />
                      }
                    />
                  </Grid>

                  {
                    // Site web de l'association
                  }
                  <Grid item>
                    <Controller
                      name="website"
                      control={control}
                      defaultValue={asso.website}
                      render={({field, fieldState: {error}}) => 
                        <TextField
                          {...field}
                          fullWidth
                          variant="outlined"
                          label="Site web de l'association"
                          error={!!error}
                          helperText={error? error.message: null}
                        />
                      }
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      type="submit"
                    >Terminer la modification du profil</Button>
                  </Grid>

                </Grid>
              )}
            </form>

        </Container>
      ):(
        <CircularProgress />
      )*/}
    </Container>
  )
}