import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { Button, CircularProgress, Container, TextField, Typography, Grid, FormControlLabel, RadioGroup, Radio, Autocomplete } from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import mapboxSdk from '@mapbox/mapbox-sdk/services/geocoding';
import { constants } from '../../common/constants';
import { AuthContext } from '../../navigation/AuthProvider';
import { Checkbox, FormControl, FormGroup, FormLabel } from '@material-ui/core';
import {useSnackbar} from 'notistack';
import { Frequency } from '../../common/enum';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from "react-day-picker/moment";
import 'moment/locale/fr';
import { Role } from '../../common/enum';
import { RRule } from 'rrule';

/**
 * Composant qui permet d'ajouter ou modifier une activité. L'interface s'adapte en fonction mais globalement, c'est le même formulaire dans les deux cas.
 * @param {object} history Permet de gérer les routes empruntées par l'utilisateur en fonction de l'action qu'il effectue
 * @param {object} match Permet de récupérer l'url exacte où se trouve l'utilisateur pour l'instant
 * @returns Un formulaire d'ajout ou de modification d'une activité
 */
function AddEditActivity({ history, match }) {
    const { id } = match.params;
    const {getAllRealAsso, getActivityDetails, updateActivity, createActivity, getCurrentUser, getAllTags, getAllThemes, hasRole} = useContext(AuthContext);
    const {enqueueSnackbar} = useSnackbar();

    const isAddMode = !id;

    // Checkbox pour dire si l'acti est fictive ou non
    const [checked, setChecked] = useState(false);
    const [activity, setActivity] = useState();
    const [isFree, setIsFree] = useState("free");
    const [type, setType] = useState("punctual");
    const [precision, setPrecision] = useState("frequency");
    // Permet de voir le type de fin d'occurrence de l'acti
    // Si "date" alors l'utilisateur choisit une date de fin de récurrence
    // Si "nbOccurrences" alors l'utilisateur choisit le nombre d'occurrences de l'activité
    const [endingOccurrence, setEndingOccurrence] = useState("never");
    // Toutes les occurrences sélectionnées par l'utilisateur
    const [allDates, setAllDates] = useState([]);
    // Date de début de l'activité
    const [dateBegin, setDateBegin] = useState();
    // Fréquence de l'activité en secondes
    const [frequency, setFrequency] = useState(Frequency.WEEK);

    const [allAsso, setAllAsso] = useState([]);
    const [inputNameAsso, setInputNameAsso] = useState("");
    const [valueAsso, setValueAsso] = useState(null);
    // Valeur entrée dans le champs de l'adresse
    const [inputValue, setInputValue] = useState("");
    const [addressChoices, setAddressChoices] = useState([]);
    // Adresse sélectionnée dans les différentes propositions
    const [selectedAddress, setSelectedAddress] = useState(null);
    // Période de la journée pendant laquelle l'activité a lieu
    const [periodCheckbox, setPeriodCheckbox] = useState({
      morning: false,
      afternoon: false,
      evening: false
    })
    const [buttonClicked, setButtonClicked] = useState(false);

    const [pmr, setPmr] = useState(false);
    // Tous les tags/centres d'intérêt existant
    const [tags, setTags] = useState();
    // Ensemble des tags/centres d'intérêt associés à l'activité
    const [selectedTags, setSelectedTags] = useState([]);

    const [themes, setThemes] = useState();
    const [selectedThemes, setSelectedThemes] = useState([]);
    const [selectedMainTheme, setSelectedMainTheme] = useState(undefined);

    var mapboxClient = mapboxSdk({accessToken: constants.mapboxToken});

    /**
     * Méthode retournant un tableau d'id à partir d'un tableau de Tags
     * @param {Tag[]} tags L'ensemble des Tags assignés à l'activité
     * @returns Un tableau reprentant tous les ids des Tags
     */
    const handleTagsId = (tags) => {
      return tags.map(tag => tag.idTag);
    }

    /**
     * 
     * @param {Theme[]} themes L'ensemble des Themes assignés à l'activités
     * @returns Un tableau reprenant tous les ids des Themes
     */
    const handleThemesId = (themes) => {
      return themes.map(theme => theme.idTheme);
    }

    useEffect(() => {
      // Mise à jour d'une activité
      if (!isAddMode) {
        getActivityDetails(id)
        .then((acti) => {
          console.log(acti);
          setChecked(acti.isTest);
          setActivity(acti);
          setIsFree(acti.isFree);
          setInputValue(acti.address);
          const tagsId = handleTagsId(acti.tags);
          setSelectedTags(() => tagsId);
          const themesId = handleThemesId(acti.themes);
          setSelectedThemes(() => themesId);
          if (acti.mainTheme !== null) setSelectedMainTheme(acti.mainTheme.idTheme);
          setSelectedAddress(acti.address);
          acti.isFree ? (setIsFree("free")):(setIsFree("notFree"));

          // Gestion organisateur
          const orga = {
            name: acti.organizer.name,
            profile: {
              idProfile: acti.organizer.idProfile
            }
          }
          setValueAsso(orga);

          // Gestion période de la journée
          let tmpPeriodsCheckbox = periodCheckbox;
          if (acti.periodsDay.includes("morning")) tmpPeriodsCheckbox.morning = true;
          if (acti.periodsDay.includes("afternoon")) tmpPeriodsCheckbox.afternoon = true;
          if (acti.periodsDay.includes("evening")) tmpPeriodsCheckbox.evening = true;
          setPeriodCheckbox(tmpPeriodsCheckbox);

          if ("recurrence" in acti) {
            setType("regular");
            let recurrence = acti["recurrence"];
            // Gestion différentes dates choisies arbitrairement par l'utilisateur
            if ("recurrenceDates" in recurrence) {
              setPrecision("dates");
              // Récupération de toutes les dates de l'activité
              var tmp = [];
              for (let i in recurrence["recurrenceDates"]){
                tmp.push(new Date(recurrence["recurrenceDates"][i]));
              }
              setAllDates([...allDates, ...tmp]);
            }
            // Gestion fin récurrence activité (se termine à une date précise ou après X occurrences ou jamais)
            else if ("nextRecurrence" in recurrence) {
              setPrecision("frequency");
              switch(recurrence["freq"]) {
                case RRule.WEEKLY:
                  if (recurrence["interval"] === 1) setFrequency(Frequency.WEEK);
                  else if (recurrence["interval"] === 2) setFrequency(Frequency.TWOWEEKS);
                  break;
                case RRule.MONTHLY:
                  setFrequency(Frequency.MONTH);
                  break;
                default:
                  break;
              }
            }

            // Si on a le nombre de séances
            if (recurrence["count"] !== undefined) setEndingOccurrence("nbOccurrences");
            else if (recurrence["endRecurrence"] !== undefined) setEndingOccurrence("date");
            else setEndingOccurrence("never");
          }

          // Récupération de tous les centres d'intérêt
          var tmpTags = tagsId;
          getAllTags()
          .then(tags => {
            for (let i in tags) {
              tags[i].isChecked = false;
              if (tmpTags) {
                for (let j in tmpTags) {
                  if (tags[i].idTag === tmpTags[j]) {
                    tags[i].isChecked = true;
                  }
                }
              }
            }
            setTags(tags);
          })
          
          // Récupération de toutes les thématiques
          var tmpThemes = themesId;
          getAllThemes()
          .then(themes => {
            for (let i in themes) {
              themes[i].isChecked = false;
              for (let j in tmpThemes) {
                if (themes[i].idTheme === tmpThemes[j]) {
                  themes[i].isChecked = true;
                }
              }
            }
            setThemes(themes);
          })
        })
        .catch(e => {
          history.push("/form");
        })

      }
      // Création d'une nouvelle activité
      else {
        getAllTags()
        .then(tags => {
          for (let i in tags) tags[i].isChecked = false;
          setTags(tags);
        })
        .catch(e => {
          enqueueSnackbar("Les centres d'intérêt n'ont pas pu être chargés.", {
            variant: "error"
          });
        })
        getAllThemes()
        .then(themes => {
          for (let i in themes) themes[i].isChecked = false;
          setThemes(themes);
        })
        .catch(e => {
          enqueueSnackbar("Les thématiques n'ont pas pu être chargées.", {
            variant: "error"
          });
        })
      }

      // Gestion de si l'utilisateur connecté est admin ou pas
      if (hasRole(Role.ADMIN)) {
        getAllRealAsso()
        .then((response) => setAllAsso(response.data))
        .catch(e => {
          enqueueSnackbar(e, {
            variant: "error"
          });
        })
      }
    }, [])

    /**
     * Gestion de la sélection de la période de la journée (matin, après-midi ou soir)
     * @param {any} event Evénement déclenché lorsqu'un utilisateur appuie sur une checkbox
     */
    const handlePeriodChange = (event) => {
      setPeriodCheckbox({
        ...periodCheckbox,
        [event.target.name]: event.target.checked,
      });
    };

    /**
     * Gestion de la sélection des différentes dates de l'activité pour une activité récurrente dont on choisit toutes les dates
     * @param {any} day Une journée sélectionné par l'utilisateur
     */
    const handleClick = (day) => {
      let index = allDates.findIndex(selectedDay => DateUtils.isSameDay(selectedDay, day));
      if (index !== -1) setAllDates(old => old.filter((_, i) => i !== index));
      else setAllDates(old => [...old, day]);
    }

    // form validation rules 
    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .required('Veuillez donner un nom à votre activité'),
      dateBegin: Yup.date()
        .required("Veuillez indiquer la date de l'événement"),
      description: Yup.string()
        .required("Veuillez fournir une description détaillée de l'activité"),
      isFree: Yup.string()
        .required("Veuillez préciser si votre activité est gratuite ou payante"),
    });

    // functions to build form returned by useForm() hook
    const { handleSubmit, control } = useForm({
      resolver: yupResolver(validationSchema)
    });

    /**
     * Transformation d'une date en format UTC
     * @param {Date} date Date à transformer
     * @returns 
     */
    const dateUTC = (date) => {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 21, 0, 0));
    }

    /**
     * Gestion de l'envoi du formulaire une fois rempli
     * @param {object} data Données du formulaire sauvegardées dans la plateforme
     */
    function onSubmit(data) {
      if (((hasRole(Role.ADMIN) && valueAsso !== null) || !hasRole(Role.ADMIN)) && selectedAddress !== null && (endingOccurrence === "nbOccurrences" || data.repeatEnd !== "" || allDates.length > 0 || endingOccurrence === "never")) {
        if (checked) data["isTest"] = true;
        data.isFree === "free"?(data.isFree = true):(data.isFree = false);
        if (data.isFree === true) data.priceComment = "";
        if (data.type === "punctual") data.frequency = "";

        // Si on a sélectionné une autre adresse que celle de l'association
        if (typeof data.address === 'object') {
          data["latitude"] = data.address.geometry.coordinates[1];
          data["longitude"] = data.address.geometry.coordinates[0];
          data.address = data.address["place_name_fr"];
        }
        // Si on a laissé l'adresse automatique renseignée à partir de l'association choisie
        else {
          data["latitude"] = valueAsso.latitude;
          data["longitude"] = valueAsso.longitude;
          data.address = valueAsso.address;
        }

        var periods = [];
        for (let el in periodCheckbox) {
          if (periodCheckbox[el] === true) periods = periods.concat(el);
        }
        data["periods"] = periods;

        if (selectedTags) data.tags = selectedTags;
        if (selectedThemes) data.themes = selectedThemes;
        if (selectedMainTheme !== undefined) data.mainTheme = selectedMainTheme;
        
        // Gestion cas où c'est un admin qui ajoute/modifie une activité
        if (hasRole(Role.ADMIN)) {
          data.idAsso = valueAsso.profile.idProfile;
          delete data.nameAsso;
        }

        // ------------------
        // Gestion récurrence
        // ------------------
        if (data.type ==="regular") {
          // Si on choisit la fréquence de l'activité
          if (precision === "frequency") {
            let rruleOptions = {};
            // Ajout date de début de la récurrence
            rruleOptions["dtstart"] = dateUTC(data.dateBegin);

            // Gestion de la fréquence
            switch(frequency) {
              case Frequency.WEEK:
                rruleOptions["freq"] = RRule.WEEKLY;
                break;
              case Frequency.TWOWEEKS:
                rruleOptions["freq"] = RRule.WEEKLY;
                rruleOptions["interval"] = 2;
                break;
              case Frequency.MONTH:
                rruleOptions["freq"] = RRule.MONTHLY;
                break;
              default:
                break;
            }

            // Gestion de la fin de la récurrence

            // Si on a sélectionné une date de fin
            if (endingOccurrence === "date") {
              rruleOptions["until"] = dateUTC(new Date(data.repeatEnd));
            }
            // Si on a sélectionné le nombre de séances
            else if (endingOccurrence === "nbOccurrences") {
              rruleOptions["count"] = parseInt(data.nbOccurrences);
            }

            // Récupération du jour de la semaine
            const nbWeekMonth = Math.ceil((data.dateBegin.getDate()) / 7);
            switch (data.dateBegin.getDay()) {
              case 1:
                rruleOptions["byweekday"] = RRule.MO.nth(nbWeekMonth);
                break;
              case 2:
                rruleOptions["byweekday"] = RRule.TU.nth(nbWeekMonth);
                break;
              case 3:
                rruleOptions["byweekday"] = RRule.WE.nth(nbWeekMonth);
                break;
              case 4:
                rruleOptions["byweekday"] = RRule.TH.nth(nbWeekMonth);
                break;
              case 5:
                rruleOptions["byweekday"] = RRule.FR.nth(nbWeekMonth);
                break;
              case 6:
                rruleOptions["byweekday"] = RRule.SA.nth(nbWeekMonth);
                break;
              case 0:
                rruleOptions["byweekday"] = RRule.SU.nth(nbWeekMonth);
                break;
              default:
                break;
            }

            const rrule = new RRule(rruleOptions);
            data["rrule"] = rrule.toString();
          }
          
          // Si on choisit les différentes dates de l'activité
          else if (data.precision === "dates") {
            // Classement par ordre chronologique
            const sortedDates = allDates.sort(function(a,b) {
              return new Date(a) - new Date(b);
            })

            data["recurrenceDates"] = sortedDates;
          }
        }

        // Suppression des paramètres qui ne nous intéressent plus/pas
        delete data.nbOccurrences;
        delete data.repeatEnd;
        delete data.frequency;
        delete data.precision;

        console.log(data);

        isAddMode
            ? createActi(data)
            : updateActi(id, data);
      }
    }

    /**
     * Cr"ation d'une activité
     * @param {object} data Données de création de l'activité
     */
    async function createActi(data) {
      let idProfile = getCurrentUser().profile.idProfile;
      const result = await createActivity(idProfile, data);
      if (!result.statusCode) history.push('/form/activities');
    }

    /**
     * Modification d'une activité
     * @param {number} idActi Id de l'activité
     * @param {object} data Données de modification de l'activité
     */
    async function updateActi(idActi, data) {
      let idProfile;
      if (hasRole(Role.ADMIN)) {
        idProfile = getCurrentUser().profile.idProfile;
      } else {
        idProfile = activity.organizer.idProfile;
      }
      const update = await updateActivity(idActi, idProfile, data);
      if (!update.statusCode) history.push('/form/activities');
    }

    return (
      <Container maxWidth="lg">
        {activity === undefined && !isAddMode ? (
          <CircularProgress />
        ):(
          <Grid>
            <Typography variant="h4">{isAddMode?("Ajout d'une activité"):("Modification d'une activité")}</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container direction="column" rowSpacing={2}>

                <Grid item>
                  <Typography variant="h5">{"Informations générales"}</Typography>
                </Grid>

                {hasRole(Role.SUPERADMIN) && (
                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={(event) => setChecked(event.target.checked)}
                          />
                        }
                        label="Activité fictive"
                      />
                    </FormGroup>
                  </Grid>
                )}

                {/* Nom de l'association */
                hasRole(Role.ADMIN) && (
                  <Grid item>
                    <Autocomplete
                      value={valueAsso}
                      onChange={(event, newValue) => {
                        setValueAsso(newValue);
                        if (newValue !== null && newValue.address !== "") {
                          setInputValue(newValue.address);
                          setSelectedAddress(newValue.address);
                        }
                      }}
                      inputValue={inputNameAsso}
                      onInputChange={(event, newInput) => setInputNameAsso(newInput)}
                      options={allAsso}
                      getOptionLabel={(option) => option.name || ""}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Nom de l'association"
                          variant="outlined"
                          error={valueAsso === null && buttonClicked === true}
                          helperText={valueAsso === null && buttonClicked === true? "Veuillez sélectionner une association" : null}
                        />
                      )}
                    />
                  </Grid>
                )}

                {/* Nom de l'activité */}
                <Grid item>
                  <Controller
                    render={({field, fieldState: {error}}) => 
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        label="Nom de l'activité"
                        error={!!error}
                        helperText={error? error.message: null}
                      />
                    }
                    name="name"
                    control={control}
                    defaultValue={isAddMode?(""):(activity.name)}
                  />
                </Grid>

                {/* Adresse de l'activité */}
                <Grid item>
                  <Typography>Par défaut, le champ de l'adresse est pré rempli avec l'adresse de l'association, si elle est renseignée. Vous êtes néanmoins tout à fait libre de choisir une autre adresse.</Typography>
                </Grid>

                <Grid item>
                  <Controller
                    name="address"
                    control={control}
                    defaultValue={isAddMode?(""):(activity.address)}
                    render={({field: {onChange, value}, fieldState: {error}}) => 
                      <Autocomplete
                        value={value}
                        onChange={(e, newValue) => {
                          // On enregistre le fait que l'utilisateur a cliqué sur une adresse ou a supprimé l'adresse sélectionnée
                          setSelectedAddress(newValue);
                          onChange(newValue);
                        }}
                        filterOptions={(x) => x}
                        inputValue={inputValue}
                        onInputChange={(event, newInput) => {
                          setInputValue(newInput);
                          if (newInput.length > 2){
                            mapboxClient.forwardGeocode({
                              query: newInput,
                              countries: ['fr', 'be'],
                              language: ['fr'],
                              autocomplete: true,
                              types: ["address", "postcode", "place", "locality"]
                            })
                            .send()
                            .then(response => {
                              setAddressChoices(response.body.features)
                            })
                          }
                        }}
                        freeSolo
                        options={addressChoices}
                        getOptionLabel={(option) => option["place_name_fr"] || ""}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            label="Adresse / Code postal de l'activité"
                            error={selectedAddress === null && buttonClicked === true}
                            helperText={selectedAddress === null && buttonClicked === true ? "Veuillez indiquer l'adresse où l'activité aura lieu": null}
                          />
                        }
                      />
                    }
                  />
                </Grid>

                {/* Date de début de l'activité */}
                <Grid item>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <Controller
                      render={({field: {onChange, value}, fieldState: {error}}) =>
                        <DatePicker
                          label="Date de début"
                          inputFormat="DD/MM/yyyy"
                          renderInput={params => 
                            <TextField
                              {...params}
                              helperText={dateBegin === undefined && buttonClicked === true ? "Veuillez sélectionner la date de début de l'activité":null}
                              error={dateBegin === undefined && buttonClicked === true}
                            />
                          }
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            if (e !== null) {
                              setAllDates(() => [e._d]);
                              setDateBegin(e._d);
                            } else {
                              setAllDates(() => []);
                              setDateBegin();
                            }
                          }}
                        />
                      }
                      name="dateBegin"
                      control={control}
                      defaultValue={isAddMode?(""):(activity.dateBegin)}
                    />
                  </LocalizationProvider>
                </Grid>

                {/* Date de fin de l'activité */}
                <Grid item>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <Controller
                      render={({field: {onChange, value}, fieldState: {error}}) =>
                        <DatePicker
                          label="Date de fin (optionnel)"
                          inputFormat="DD/MM/yyyy"
                          renderInput={params => <TextField {...params} error={false}/>}
                          value={value}
                          onChange={onChange}
                          helperText={error?.message}
                        />
                      }
                      name="dateEnd"
                      control={control}
                      defaultValue={isAddMode?(""):(activity.dateEnd)}
                    />
                  </LocalizationProvider>
                </Grid>

                {/* Période de la journée */}
                <Grid item>
                  <FormControl>
                    <FormLabel>Période de la journée</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        label="Matin"
                        value="morning"
                        control={
                          <Checkbox
                            checked={periodCheckbox.morning}
                            onChange={handlePeriodChange}
                            name="morning"
                          />
                        }
                      />

                      <FormControlLabel
                        label="Après-midi"
                        value="afternoon"
                        control={
                          <Checkbox
                            checked={periodCheckbox.afternoon}
                            onChange={handlePeriodChange}
                            name="afternoon"
                          />
                        }
                      />

                      <FormControlLabel
                        label="Soir"
                        value="evening"
                        control={
                          <Checkbox
                            checked={periodCheckbox.evening}
                            onChange={handlePeriodChange}
                            name="evening"
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>

                {/* Résumé de l'activité */}
                <Grid item>
                  <Controller
                    name="summary"
                    control={control}
                    defaultValue={isAddMode?(""):(activity.summary)}
                    render={({field, fieldState: {error}}) => 
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        multiline
                        label="Résumé de l'activité (optionnel)"
                        error={!!error}
                        helperText={error? error.message: null}
                      />
                    }
                  />
                </Grid>

                {/* Description détaillée de l'activité */}
                <Grid item>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue={isAddMode?(""):(activity.description)}
                    render={({field, fieldState: {error}}) => 
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        multiline
                        label="Description détaillée de l'activité"
                        error={!!error}
                        helperText={error? error.message: null}
                      />
                    }
                  />
                </Grid>

                {/* Prix de l'activité */}
                <Grid item>
                  <FormLabel>Prix de l'activité</FormLabel>
                  <Controller
                    name="isFree"
                    control={control}
                    defaultValue={isAddMode?("free"):(activity.isFree?("free"):("notFree"))}
                    render={({field:{onChange, value}}) => 
                      <RadioGroup
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setIsFree(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="free"
                          control={<Radio />}
                          label="Gratuit"
                        />
                        <FormControlLabel
                          value="notFree"
                          control={<Radio />}
                          label="Payant"
                        />
                      </RadioGroup>
                    }
                  />
                </Grid>

                {isFree === "notFree" && (
                  // Description du prix de l'activité
                  <Grid item>
                    <Controller
                      name="priceComment"
                      control={control}
                      defaultValue={isAddMode?(""):(activity.priceComment)}
                      render={({field, fieldState: {error}}) => 
                        <TextField
                          {...field}
                          fullWidth
                          variant="outlined"
                          multiline
                          label="Prix de l'activité"
                          error={!!error}
                          helperText={error?.message}
                        />
                      }
                    />
                  </Grid>
                )}



                {/* RECURRENCE DE L'ACTIVITE */}

                <Grid item>
                  <FormLabel>Récurrence de l'activité</FormLabel>
                  <Controller
                    name="type"
                    control={control}
                    defaultValue={isAddMode?("punctual"):(activity.type)}
                    render={({field:{onChange, value}}) => 
                      <RadioGroup
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setType(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value="punctual"
                          control={<Radio />}
                          label="Activité ponctuelle"
                        />
                        <FormControlLabel
                          value="regular"
                          control={<Radio />}
                          label="Activité régulière"
                        />
                      </RadioGroup>
                    }
                  />
                </Grid>

                {/* Type de récurrence de l'activité: fréquence ou choix des différentes dates */}
                {type === "regular" && (
                  <Grid item>
                    <FormLabel>Précision sur la récurrence</FormLabel>
                    <Controller
                      name="precision"
                      control={control}
                      defaultValue={isAddMode?("frequency"):(activity["recurrence"] !== undefined && "notYetPassedDates" in activity["recurrence"] ? ("dates"):("frequency"))}
                      render={({field: {onChange, value}}) =>
                        <RadioGroup
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            setPrecision(e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="frequency"
                            control={<Radio />}
                            label="Choisir la fréquence de l'activité"
                          />
                          <FormControlLabel
                            value="dates"
                            control={<Radio />}
                            label="Choisir les différentes dates de l'activité"
                          />
                        </RadioGroup>
                      }
                    />
                  </Grid>
                )}

                {/* Fréquence de la récurrence de l'activité */}
                {type === "regular" && precision === "frequency" && (
                  <Grid item>
                    <FormLabel>Fréquence</FormLabel>
                    <RadioGroup
                      defaultValue={isAddMode?(
                        Frequency.WEEK
                      ):(
                        activity["recurrence"] !== undefined ? (
                          activity["recurrence"]["freq"] === RRule.WEEKLY ? (
                            // WEEK ou TWO WEEKS ?
                            activity["recurrence"]["interval"] === 1 ? (
                              // WEEK
                              Frequency.WEEK
                            ):(
                              // TWO WEEKS
                              Frequency.TWOWEEKS
                            )
                          ) : (
                            Frequency.MONTH
                          )
                        ) : (
                          Frequency.WEEK
                        )
                      )}
                      onChange={(e) => setFrequency(parseInt(e.target.value))}
                    >
                      <FormControlLabel
                        value={Frequency.WEEK}
                        control={<Radio />}
                        label="Toutes les semaines"
                      />
                      <FormControlLabel
                        value={Frequency.TWOWEEKS}
                        control={<Radio />}
                        label="Toutes les deux semaines"
                      />
                      <FormControlLabel
                        value={Frequency.MONTH}
                        control={<Radio />}
                        label="Tous les mois"
                      />
                    </RadioGroup>
                  </Grid>
                )}

                {/* Fin de la récurrence */}
                {type === "regular" && precision === "frequency" && (
                  <Grid item>
                    <FormLabel>Fin de la fréquence de l'activité</FormLabel>
                    <RadioGroup
                      value={endingOccurrence}
                      onChange={e => setEndingOccurrence(e.target.value)}
                    >
                      <Grid item>
                        <FormControlLabel
                          value="never"
                          control={<Radio />}
                          label="Ne se termine jamais"
                        />
                      </Grid>
                      <Grid item container>
                        <FormControlLabel
                          value="date"
                          control={<Radio />}
                          label="Se termine le"
                        />
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <Controller
                            render={({field: {onChange, value}, fieldState: {error}}) =>
                              <DatePicker
                                inputFormat="DD/MM/yyyy"
                                renderInput={params => <TextField {...params}/>}
                                value={value}
                                onChange={e => {
                                  if (e !== null) onChange(e._d);
                                }}
                                helperText={error?.message}
                                disabled={endingOccurrence !== "date" ? (true):(false)}
                              />
                            }
                            name="repeatEnd"
                            control={control}
                            defaultValue={isAddMode?(""):(activity["recurrence"] !== undefined && "endRecurrence" in activity["recurrence"] ? (activity["recurrence"]["endRecurrence"]):(""))}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item container direction="row" alignItems="center">
                        <FormControlLabel
                          value="nbOccurrences"
                          control={<Radio />}
                          label="Se termine après"
                        />
                        <Controller
                          name="nbOccurrences"
                          control={control}
                          defaultValue={isAddMode?("2"):(
                            activity["recurrence"] !== undefined && "count" in activity["recurrence"] ? (activity["recurrence"]["count"]):("2")
                          )}
                          render={({field, fieldState: {error}}) => 
                            <TextField
                              {...field}
                              type="number"
                              variant="outlined"
                              error={!!error}
                              helperText={error?.message}
                              disabled={endingOccurrence !== "nbOccurrences" ? (true):(false)}
                            />
                          }
                        />
                        <Typography style={{paddingLeft: 10}}>séances</Typography>
                      </Grid>
                    </RadioGroup>
                  </Grid>
                )}

                {/* Dates des récurrences de l'activité */}
                {type === "regular" && precision === "dates" && (
                  <Grid item>
                    <FormLabel>Dates de l'activité</FormLabel>
                    <DayPicker
                      localeUtils={MomentLocaleUtils}
                      locale="fr"
                      selectedDays={allDates}
                      onDayClick={handleClick}
                      disabledDays={{
                        before: dateBegin
                      }}
                    />
                  </Grid>
                )}

                <Grid item>
                  <Typography variant="h5">{"Quelques informations supplémentaires"}</Typography>
                </Grid>

                <Grid item>
                  <Controller
                    name="inscriptionDescription"
                    control={control}
                    defaultValue={isAddMode?(""):(activity.inscriptionDescription)}
                    render={({field, fieldState: {error}}) => 
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        multiline
                        label="Description de la procédure à suivre pour s'inscrire (optionnel)"
                        error={!!error}
                        helperText={error? error.message: null}
                      />
                    }
                  />
                </Grid>

                <Grid item>
                  <Controller
                    name="comment"
                    control={control}
                    defaultValue={isAddMode?(""):(activity.comment)}
                    render={({field, fieldState: {error}}) => 
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        multiline
                        label="Commentaire éventuel (optionnel)"
                        error={!!error}
                        helperText={error? error.message: null}
                      />
                    }
                  />
                </Grid>

                <Grid item>
                  <Controller
                    name="maxParticipants"
                    control={control}
                    defaultValue={isAddMode?(""):(activity.maxParticipants)}
                    render={({field, fieldState: {error}}) => 
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        multiline
                        label="Nombre maximum de participants (optionnel)"
                        error={!!error}
                        helperText={error? error.message: null}
                      />
                    }
                  />
                </Grid>

                <Grid item>
                  <FormLabel>Accessibilité aux personnes à mobilité réduite</FormLabel>
                  <Controller
                    name="pmrAccessibility"
                    control={control}
                    defaultValue={isAddMode?(true):(activity.pmrAccessibility)}
                    render={({field: {onChange, value}, fieldState: {error}}) => 
                      <RadioGroup
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          //setPmr(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Oui"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Non"
                        />
                      </RadioGroup>
                    }
                  />
                </Grid>

                {/* TODO: ajouter champ pmrComment dans la bd */}
                {pmr === true && (
                  <Grid item>
                    
                  </Grid>
                )}

                {/* Centres d'intérêt liés à l'activité */}
                <Grid item columns={3} xs={6}>
                  <FormControl>
                    <FormLabel>Centres d'intérêt liés à l'activité</FormLabel>
                    {tags? (
                      <FormGroup>
                        {tags.map((tag, index) => (
                          <FormControlLabel
                            key={tag.idTag}
                            value={tag.idTag}
                            label={tag.name}
                            control={
                              <Checkbox
                                checked={tag.isChecked}
                                onChange={(e) => {
                                  // MAJ du champ "isChecked" disant si un item est sélectionné ou non
                                  setTags(tags.map((item, position) => position === index ? {...tags[position], isChecked: !item.isChecked} : item));

                                  // MAJ du tableau contenant les ID des centres d'intérêt sélectionnés par l'utilisateur qui sera envoyé dans la requête
                                  if (selectedTags.includes(tag.idTag)) {
                                    setSelectedTags(selectedTags.filter(function(value) {
                                      return value !== tag.idTag;
                                    }))
                                  } else {
                                    setSelectedTags(selectedTags.concat(tag.idTag));
                                  }
                                }}
                              />
                            }
                          />
                        ))}
                      </FormGroup>
                    ):(
                      <CircularProgress />
                    )}
                  </FormControl>
                </Grid>

                {/* Thématiques liées à l'activité */}
                <Grid item>
                  <Typography variant="h6">{"Gestion des thématiques"}</Typography>
                </Grid>

                <Grid item>
                  <FormControl>
                    <FormLabel>Thématique principale</FormLabel>
                    <Typography>Si vous le souhaitez, vous pouvez sélectionner une thématique principale.</Typography>
                    {themes ? (
                      <RadioGroup
                        value={selectedMainTheme}
                        onChange={(e) => {
                          setSelectedMainTheme(parseInt(e.target.value));
                        }}
                      >
                        {themes.map((theme, index) => (
                          <FormControlLabel
                            key={theme.idTheme}
                            value={theme.idTheme}
                            label={theme.name}
                            control={<Radio />}
                          />
                        ))}
                      </RadioGroup>
                    ):(
                      <CircularProgress />
                    )}
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl>
                    <FormLabel>Thématiques secondaires</FormLabel>
                    <Typography>Si vous le souhaitez, vous pouvez sélectionner une ou plusieurs thématiques secondaires.</Typography>
                    {themes ? (
                      <FormGroup>
                        {themes.map((theme, index) => (
                          <FormControlLabel
                            key={theme.idTheme}
                            value={theme.idTheme}
                            label={theme.name}
                            control={
                              <Checkbox
                                checked={selectedMainTheme === theme.idTheme ? false : theme.isChecked}
                                disabled={selectedMainTheme === theme.idTheme}
                                onChange={(e) => {
                                  // MAJ du champ "isChecked" disant si un item est sélectionné ou non
                                  setThemes(themes.map((item, position) => position === index ? {...themes[position], isChecked: !item.isChecked} : item));

                                  // MAJ du tableau contenant les ID des centres d'intérêt sélectionnés par l'utilisateur qui sera envoyé dans la requête
                                  if (selectedThemes.includes(theme.idTheme)) {
                                    setSelectedThemes(selectedThemes.filter(function(value) {
                                      return value !== theme.idTheme;
                                    }))
                                  } else {
                                    setSelectedThemes(selectedThemes.concat(theme.idTheme));
                                  }
                                }}
                              />
                            }
                          />
                        ))}
                      </FormGroup>
                    ):(
                      <CircularProgress />
                    )}
                  </FormControl>
                </Grid>
                
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={() => setButtonClicked(true)}
                  >{isAddMode?("Ajouter une activité"):("Terminer les modifications")}</Button>
                </Grid>

              </Grid>

            </form>
            
          </Grid>
        )}

      </Container>
    );
}

export { AddEditActivity };